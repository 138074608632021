import React from 'react';
import Sodexo from './Sodexo';
import { connect } from 'react-redux';
import {withCookies} from 'react-cookie';

import ticketImg from './../../images/web-images/ticketrest.png';
import sodexImg from './../../images/web-images/sodexo.png';

import {selectMealVoucher} from './../../actions/paymentActions';
import {getSavedCard} from './../../utils/PaymentGateway';


class MealVoucher extends React.Component {
    cardStatus;

    componentDidMount() {
        this.checkCardSaved();
    }
    
    checkCardSaved = async () => {
        const {cookies} = this.props.cookies;
        const card = await getSavedCard(cookies.id,cookies.token);
        const {sodexo} = card.data;
        
        this.cardStatus = sodexo.length === 0 ? 0 : 1;
    }

    render() {
        const {sodexo, ticketrestaurant} = this.props;
        return (
            <div>
                <div class="row">
                    <div className="col-md-1 col-sm-1"></div>
                    <div class="col-md-5 col-sm-5">
                        <div class="form-group">
                            <input 
                                type="radio" 
                                name="mealvoucher"
                                {...sodexo.isActive ? {checked: true} : {}} 
                                onChange={() => this.props.selectMealVoucher('sodexo')}
                            />
                            <img src={sodexImg} height="30px" style={{marginLeft:'15px'}}/>
                        </div>
                    </div>
        
                    <div class="col-md-5 col-sm-5">
                        <div class="form-group">
                            <input 
                                type="radio" 
                                name="mealvoucher"
                                {...ticketrestaurant ? {checked: true} : {}} 
                                onChange={() => this.props.selectMealVoucher('ticketrestaurant')}
                            />
                            <img src={ticketImg} height="30px" style={{marginLeft:'15px'}} />
                        </div>
                    </div>
                </div>

                {
                    sodexo.isActive && this.cardStatus ? (
                       <Sodexo />
                    ): ''
                }
                
            </div>
        );
    }
}

const mapStateToProps = state => state.paymentpage.paymentoption.mealvouchers;
const mapDispatchToProps = {selectMealVoucher};

export default withCookies(connect(mapStateToProps,mapDispatchToProps)(MealVoucher));