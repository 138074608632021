import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import {setSelectedRestaurant} from './../../actions/restActions';
import {setRestFoodCourt,emptyCart} from './../../actions/orderActions';
import {openModal, closeModal} from './../../actions/modalActions';
import {setMenu} from './../../actions/menuActions';
import {setCategory} from './../../actions/categoryActions';
import {setCuisines} from './../../actions/cuisineActions';
import {toggleLoader} from './../../actions/loaderActions';

import { fetchRestaurant,fetchCuisines } from './../../utils/ApiLibrary';
import {fetchMenu} from './../../utils/ApiLibrary';

import ModalPopup from './ModalPopup';

class GridListRestaurant extends React.Component {

    getCuisineName = (id) => {
        return this.props.cuisines ? (
            this.props.cuisines.find(cuisine => {
                return cuisine.id === id;
            })
        ) : false 
    }

    goToMenuItems = (id) => {
        let openinghours;
        fetchCuisines('/gokhana/cuisine').then(result => {
            this.props.setCuisines(result),
            fetchRestaurant(id).then(result => {
                if(result.data){
                    
                    result.data.openinghours.forEach((openingHr) => {
                        if(moment().format('dddd').toLowerCase() === openingHr.day.toLowerCase()) {
                            openinghours = `${moment(openingHr.from,['HH:mm']).format('hh:mm A')} - ${moment(openingHr.to,['HH:mm']).format('hh:mm A')}`;
                        }
                    });

                    const isOpen = this.checkIfOpen(openinghours);

                    let restaurantData = {
                        id: result.data.id,
                        name: result.data.name,
                        address: result.data.address,
                        imageUrl: result.data.imageUrl,
                        cuisines: result.data.cuisines,
                        isExist: true,
                        openinghours,
                        isOpen
                    };
                    
                    let setFoodOrderData = {
                        parentId: result.data.id,
                        restaurantname: result.data.name,
                        foodcourt: '',
                        foodcourtname: '',
                        imageUrl: result.data.imageUrl
                    };
    
                    (this.props.filters.foodcourtOnly && this.props.filters.foodcourtCB == "true") ? (
                        setFoodOrderData.foodcourt = this.props.filters.foodcourtId,
                        setFoodOrderData.foodcourtname = this.props.filters.foodcourtName
                    ) : ''
                    
                    this.props.setRestFoodCourt(setFoodOrderData);
                    this.props.setSelectedRestaurant(restaurantData);  
                    
                    fetchMenu(id).then(result => {
                        this.props.setMenu(result);
                        const cat = {
                            id : result[0].id,
                            name: result[0].name
                        }
                        this.props.setCategory(cat);
                        this.props.history.push(`/restaurant/${id}`);  
                    });
                };
            })  
        });
    }

    truncate = (string,length) => {
        return string.length > length ? 
        string.substring(0, length - 3) + "..." : 
        string;
    }

    checkIfOpen = (openinghours) => {
        let hoursArray = openinghours.split('-');
        const currentTime = moment();
        const beforeTime = moment(hoursArray[0].trim(),'hh:mm A');
        let afterTime = moment(hoursArray[1].trim(),'hh:mm A');
        
        if(afterTime.isSameOrBefore(beforeTime)){
            afterTime = afterTime.add(1,'d');
        }
        return currentTime.isBetween(beforeTime,afterTime);
    }

    handleOnClick = (e) => {
        const id = e.target.id;
        {
            this.props.order.items.length === 0 ? (
                this.goToMenuItems(id)
            ) : (
                id !== this.props.order.parentId ? 
                    this.props.openModal('cartNotEmpty',{buttonClick: true,currentSelection:id,prevSelection:this.props.order.parentId})
                : this.goToMenuItems(id)
            )
        }
    }

    continueToCart = () => {
        const id = this.props.modal.data.prevSelection;
        this.goToMenuItems(id);
        this.props.closeModal();
    }

    newSelection = () => {
        const id = this.props.modal.data.currentSelection;
        this.props.emptyCart();
        this.goToMenuItems(id);
        this.props.closeModal();
    }
    
    render() {
        return (
            <div>
            {/*this.props.restaurant.id ? <Redirect to="/select-menu" /> : ''*/}          
            <div className="col-md-12" 
             className="strip_list" 
             onClick={this.handleOnClick}
                                    id={this.props.outlet.id} style={{cursor:"pointer"}}>
                    <div className="col-md-12 col-sm-12" id={this.props.outlet.id} >
                        <div className="desc" id={this.props.outlet.id} >
                            <div className="thumb_strip" id={this.props.outlet.id} >
                                <a href="javascript:void(0)" id={this.props.outlet.id} ><img src={this.props.outlet.imageUrl} alt="" id={this.props.outlet.id} /></a>
                            </div>
                            <div className="desc_text">
                                <h5 style={{marginBottom:"1px"}} id={this.props.outlet.id} >{this.props.outlet.name}</h5>
                                <div className="type" style={{marginBottom:"1px"}} id={this.props.outlet.id} >
                                    <ul id={this.props.outlet.id} >
                                    {
                                        this.props.outlet.cuisines                                 
                                        .map((id) => {
                                            return <li id={this.props.outlet.id} >{this.getCuisineName(id) ? this.getCuisineName(id).name : ''}</li>
                                        })
                                    }
                                    </ul>
                                </div>
                                <div className="location" id={this.props.outlet.id} >
                                    {this.props.outlet.address}
                                </div>
                                <ul id={this.props.outlet.id} >
                                    {this.props.outlet.distance ? <li>{this.props.outlet.distance}</li> : ''}
                                    <li id={this.props.outlet.id} ><span className="opening">Opens at {this.props.outlet.openinghours}</span></li>
                                </ul>
                                <ul>
                                    <li style={{color: '#ff0000', fontWeight: 'bold'}}>{!this.props.outlet.isOpen ? 'Closed Now' : ''}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  
                </div>

                <ModalPopup 
                    isModalOpen={this.props.modal.isModalOpen} 
                    handleClose={this.props.closeModal}
                    modalType={this.props.modal.modalType}
                    toCart={this.continueToCart}
                    newSelection={this.newSelection}
                    data={this.props.modal.data}
                />

            </div>
        );
    }
}

const mapDispatchToProps = {
    setSelectedRestaurant,
    setRestFoodCourt,
    openModal,
    closeModal,
    emptyCart,
    setMenu,
    setCategory,
    setCuisines,
    toggleLoader
}

export default withRouter(connect(state => state,mapDispatchToProps)(GridListRestaurant));
