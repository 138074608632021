import React from 'react';
import {connect} from 'react-redux';

import MenuItems from './MenuItems';

class MenuItemsGroup extends React.Component {

    render() {
        return(
            <div className="col-md-6" id="menu-items-group-parent">
                <div onScroll={this.props.handleOnScroll} id="menu-items-group">
                    {
                        this.props.menu.map((category,index) => {
                            return (
                                <div id={`m${index}`} className="cat_menu_items" data-categoryId = {category.id} data-categoryName = {category.name}>
                                    <div className="box_style_2" id="main_menu">
                                        <h2 className="inner">{category.name}</h2>
                                        <MenuItems cat={category}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                    
                    <div style={{'height':'500px'}}></div>
                </div>
            </div>
        );
    }
}

export default connect(state => state)(MenuItemsGroup);