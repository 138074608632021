import React from 'react';

import Paytm from './Paytm';

class OrderPaymentInfo extends React.Component {
    render() {
        return (
            <div className="col-md-6">
				<div className="box_style_2">
                    <h2 className="inner">Payment Options</h2>
                    
                    <Paytm />
					
				</div>
            </div>
        );
    }
}

export default OrderPaymentInfo;