import axios from 'axios';

const baseURL = 'https://api.ampcome.com/api';
const accessToken = 'Vqs5BArAQh8rQEtIqKTdEIQRehX5ppxhK2OJcBWD0tMIm3y2FhWXiNbWBrHctfbz';

export const loginUser = (phoneno,email = '',name = '') => {
    const api = "/entityrecords/restaurantcustomer";
    return axios.post(`${baseURL}${api}`,{phoneno},{
        headers: {"Authorization": accessToken}
    }).then(res => res).catch(e => console.log(e));
}

export const sendOTP = (phoneno) => {
    const api = "/gokhana/sendotp";

    return axios.post(`${baseURL}${api}`,{phoneno},{
        headers: {"Authorization": accessToken}
    }).then(res => res).catch(e => console.log(e));
}

export const verifyOTP = (phoneno,otp) => {
    const api = "/gokhana/verifyotp";

    return axios.post(`${baseURL}${api}`,{phoneno,otp,includeuser:true},{
        headers: {"Authorization": accessToken,"Content-Type":"application/json"}
    }).then(res => res);
}

export const getGokash = (id,token) => {
    const api = `/gokhana/restaurantcustomer/${id}/gokashcredit?clientapp=gokhanapartner`;

    return axios.get(`${baseURL}${api}`,{
        headers: {"Authorization": token}
    }).then(res => res);
}