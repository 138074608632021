import React from 'react';
import {connect} from 'react-redux';
import {withRouter,Link} from 'react-router-dom';

import {selectTab} from './../../actions/customerActions';

class CustomerAccountSidebar extends React.Component {

    setActive = (tab) => {
        this.props.selectTab(tab);
    }

    render() {
        const {selecttab} = this.props;
        return (
            <div className="col-md-3">
                <div className="box_style_1">
                    <ul id="cat_nav">
                        <li>
                            <a 
                                href="javascript:void(0)"
                                onClick={() => this.setActive('orders')} 
                                {...selecttab.orders ? {className:'active'} : {}}
                            >Orders</a>
                        </li>
                        <li>
                            <a 
                                href="javascript:void(0)" 
                                onClick={() => this.setActive('profile')} 
                                {...selecttab.profile ? {className:'active'} : {}}
                            >Profile</a>
                        </li>

                        <li id="sidebar-logout"><Link to="/logout">Logout</Link></li>
                    </ul>
                </div>
            
            </div>
        );
    }
}

const mapDispatchToProps = {
    selectTab
};

export default withRouter(connect(state => state.customer,mapDispatchToProps)(CustomerAccountSidebar));