import React from 'react';
import {connect} from 'react-redux';

import {selectOption} from './../../actions/paymentActions';

import {loadJsLibraries} from './../../utils/LoadLibrary';
import CreditCard from './CreditCard';
import NetBanking from './NetBanking';
import PaytmWallet from './PaytmWallet';
import DebitCard from './DebitCard';
import MealVoucher from './MealVoucher';

class Paytm extends React.Component {
    componentDidMount() {
        loadJsLibraries();
    }
    
    render() {
        const {cc, dc, nb, wallet,mealvouchers} = this.props;
        return (
            <div>
                <div className="payment_select">
                    <label className="radio-container">Credit Card
                        <input 
                            type="radio" 
                            value="" 
                            name="payment_method" 
                            {...cc.isActive ? {checked: true} : {}}
                            onChange={() => this.props.selectOption('cc')}
                        />
                        <span class="checkmark"></span>
                    </label>
                    <i className="icon_creditcard"></i>
                </div>
                
                {cc.isActive ? <CreditCard /> : ''}
        
                <div className="payment_select">
                    <label className="radio-container">Debit Card
                        <input 
                            type="radio" 
                            value="" 
                            name="payment_method" 
                            {...dc.isActive ? {checked: true} : {}}
                            onChange={() => this.props.selectOption('dc')}
                        />
                        <span class="checkmark"></span>
                    </label>
                    <i className="icon_creditcard"></i>
                </div>

                {dc.isActive ? <DebitCard /> : ''}

                <div className="payment_select">
                    <label className="radio-container"> Net Banking
                        <input 
                            type="radio" 
                            value="" 
                            name="payment_method" 
                            {...nb.isActive ? {checked: true} : {}}
                            onChange={() => this.props.selectOption('nb')}
                        />
                        <span class="checkmark"></span>
                    </label>
                    <i className="icon_laptop"></i>
                </div>

                {nb.isActive ? <NetBanking /> : ''}

                <div className="payment_select">
                    <label className="radio-container"> Wallet
                        <input 
                            type="radio" 
                            value="" 
                            name="payment_method" 
                            {...wallet.isActive ? {checked: true} : {}}
                            onChange={() => this.props.selectOption('wallet')}
                            />
                            <span class="checkmark"></span>
                        </label>
                    <i className="icon_wallet"></i>
                </div>

                {wallet.isActive ? <PaytmWallet /> : ''}

                <div className="payment_select">
                    <label className="radio-container"> Meal Vouchers
                        <input 
                            type="radio" 
                            value="" 
                            name="payment_method" 
                            {...mealvouchers.isActive ? {checked: true} : {}}
                            onChange={() => this.props.selectOption('mealvouchers')}
                            />
                            <span class="checkmark"></span>
                        </label>
                    <i className="icon-credit-card-4"></i>
                </div>

                {mealvouchers.isActive ? <MealVoucher /> : ''}
            </div>
        );
    }
}

export default connect(state => state.paymentpage.paymentoption,{selectOption})(Paytm);