import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {store, persistor} from './store';
import {CookiesProvider} from 'react-cookie';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-rangeslider/lib/index.css';

import Routers from './routes/AppRouter';

import './styles/google-font.css';
import './styles/base.css';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} >
            <CookiesProvider>
                <Routers />
            </CookiesProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
