import React from 'react';
import {withCookies} from 'react-cookie';
import {connect} from 'react-redux';
import validator from 'validator';

import {updateCustDetails} from './../../utils/CustomerLibrary';
import {getSetCustDetails,setEmail,setName,toggleIsUpdated} from './../../actions/customerActions';
import {setCustomer} from './../../actions/orderActions';

class UpdateCustomerInfo extends React.Component {
    componentDidMount() {
        const {cookies} = this.props.cookies;
        let customerData = {
            name: {
                value: cookies.name,
                isValid: true,
                error: ''
            },
            email: {
                value: cookies.email,
                isValid: true,
                error: ''
            }
        };
        this.props.getSetCustDetails(customerData);
    }
    
    validateFullName = (name) => {
        const fullName = name.split(" ");
        let isValid = false;

        fullName.length <=3 ? (
            isValid = validator.isAlpha(fullName[0]) && validator.isLength(fullName[0],{min:3,max:30}),
            fullName[1] && isValid ? isValid = validator.isAlpha(fullName[1]) : '',
            fullName[2] && isValid ? isValid = validator.isAlpha(fullName[2]) : ''
        ) : '';

        return isValid;
    }

    validateName = (e) => {
        let name = e.target.value;
        const isValid = this.validateFullName(name);
        !isValid ? 
            this.props.setName({value:name,isValid:false,error:'Enter a valid name.'}) : 
            this.props.setName({value:name,isValid:true,error:''});
    }

    validateEmail = (e) => {
        let email = e.target.value;
        const isValid = validator.isEmail(email);
        !isValid ? 
            this.props.setEmail({value:email,isValid:false,error:'Enter a valid email address.'}) : 
            this.props.setEmail({value:email,isValid:true,error:''});
    }

    update = () => {
        const {name,email} = this.props.updateCustomerDetails;
        const cookie = this.props.cookies.cookies;
        const {cookies} = this.props;

        (email.isValid && name.isValid) ? (
            updateCustDetails(cookie.id,{name:name.value,email:email.value},cookie.token).then(res => {
                this.props.toggleIsUpdated(true);
                this.props.setCustomer({
                    restaurantcustomer: res.data.id,
                    customername: res.data.name,
                    customerno: res.data.phoneno,
                    createdby: res.data.id
                });

                cookies.set('name',_.startCase(res.data.name,{path:'/'}));
                cookies.set('email',res.data.email,{path:'/'});

                setTimeout(() => {
                    this.props.toggleIsUpdated(false);
                }, 5000);
            })
        ) : ''
    }

    render() {
        const {cookies} = this.props.cookies;
        const {updateCustomerDetails,isUpdated} = this.props;
        return (
            <div className="col-md-6">
                <div className="box_style_2" id="order_process">
                    <h2 className="inner">Your Profile</h2>
                    {isUpdated ?  <p style={{fontSize:'14px',color:'#008000',textAlign:'left'}}>Profile updated successfully.</p> : ''}
                    <div className="form-group">
                        <label>Mobile</label>
                        <input type="text" id="tel_order" className="form-control" value={cookies.phoneno} disabled/>
                    </div>
                    <div className="form-group">
                        <label>Name</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            onInput={this.validateName} 
                            value={updateCustomerDetails.name.value === 'null' ? '-' : updateCustomerDetails.name.value}
                        />
                        <p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{updateCustomerDetails.name.error}</p>
                    </div>

                    <div className="form-group">
                        <label>Email</label>
                        <input 
                            type="email" 
                            className="form-control" 
                            onInput={this.validateEmail}
                            value={updateCustomerDetails.email.value === 'null' ? '-' : updateCustomerDetails.email.value}
                        />
                        <p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{updateCustomerDetails.email.error}</p>
                    </div>

                    <button type="submit" class="btn_1 green" onClick={this.update}>Update</button>
                    
                </div>
            </div>
        );
    }
}
const mapStateToProps = {
    setEmail,
    setName,
    getSetCustDetails,
    setCustomer,
    toggleIsUpdated
};
export default withCookies(connect(state => state.customer,mapStateToProps)(UpdateCustomerInfo));