import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';

import {openModal, closeModal} from './../../actions/modalActions';
import {toggleLoader} from './../../actions/loaderActions';
import {setLocation} from './../../actions/locationActions';
import {reOrder} from './../../actions/orderActions';

import logoImage from './../../images/gokhana-logo.png';
import ModalPopup from './ModalPopup';
import OrderSummary from './OrderSummary';

class Header extends React.Component {

    goToCart = () => {
        this.props.history.push(`/restaurant/${this.props.order.parentId}`);
    }

    handleClose = () => {
        this.props.closeModal();
        const {order} = this.props;
        const {modal} = this.props;
        
        if(!modal.data.buttonClick){
            order.parentId ? this.props.history.push(`/restaurant/${order.parentId}`) : this.props.history.push('/');
        }  
    }

    selectCart = () => {
        const {parentId} = this.props.order;
        this.props.closeModal();
        this.props.history.push(`/restaurant/${parentId}`);
    }

    reorderLogic = () => {
        const {orderid} = this.props.modal.data;
        const {orderHistory} = this.props;
        let singleOrder = orderHistory.find((order) => {
            return order.orderid === orderid;
        });

		let reorderData = _.omit(singleOrder,['createdAt','id','lastupdatedby','orderid','ordertype','updatedAt']);
		reorderData.paidamount = 0;
		reorderData.paid = false;
		reorderData.paymenttransactions = [];
		reorderData.placedtime = '';
        this.props.reOrder(reorderData);
        this.props.closeModal();
		this.props.history.push(`/restaurant/${reorderData.parentId}`);
	}

    render() {
        const {city} = this.props.location;
        const {isAuthenticated} = this.props.customer;
        const cookies = this.props.cookies.cookies;
        return (
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col--md-4 col-sm-4 col-xs-4">
                            <Link to="/" id="logo">
                                <img src={logoImage}  height="25" alt="" data-retina="true" style={{marginTop:"8px"}}/>
                            </Link>
                        </div>
                        <nav className="col--md-8 col-sm-8 col-xs-8">
                            {/* Mobile device */}
                            
                            <a 
                                href="javascript:void(0)" 
                                title={this.props.location.city} 
                                className="mobile-city"
                                id="mobile-change-location"
                            >
                                <i className="icon-location-7"></i> 
                                {this.props.location.city && this.props.location.city.length > 10 ? `${this.props.location.city.substring(0,10)}...` : this.props.location.city} 
                                <i className="icon-down-open-mini"></i>
                            </a>

                            <Link to="/my-account" className="mobile-myaccount">
                                <i className="icon-user-7"></i> 
                            </Link>
                            
                            <a 
                                href="javascript:void(0)"
                                className="mobile-cart"
                                id="m-cart"
                            >
                                <i className="icon_cart_alt"></i> 
                                {
                                    this.props.order.items.length !==0 ? (
                                        <sup>{this.props.order.itemscount}</sup>
                                    ):''
                                }
                            </a>

                            <div id="display-mobile-cart">
                                <div id="pointer"></div>    
                                <OrderSummary />
                            </div>
                            {/* Big devices */}
                            <div className="main-menu">
                                <div id="header_menu">
                                    <img src={logoImage} height="30" alt="" data-retina="true" />
                                </div>
                                <a href="javascript:void(0)" className="open_close" id="close_in"><i className="icon_close"></i></a>
                                <ul>
                                {
                                    isAuthenticated ? (
                                        <li className="submenu">
                                            <a 
                                                href="javascript:void(0);" 
                                                className="show-submenu">
                                                <i className ="icon-user"></i>Account<i className="icon-down-open-mini"></i>
                                            </a>
                                            <ul>
                                                <li>
                                                    <a href="javascript:void(0)">
                                                        {cookies.name !== 'null' ? <p>{cookies.name}</p>: '' }
                                                        {cookies.phoneno !== 'null' ? <p>{cookies.phoneno}</p> : '' }
                                                        {cookies.email !== 'null' ? <p>{cookies.email}</p> : '' }
                                                    </a>
                                                </li>
                                                {/*<li>
                                                    <a href="javascript:void(0)"><i className ="icon-money"></i>
                                                        GoKash Credit: {cookies.gokash}
                                                    </a>
                                                </li>*/}
                                                <li>
                                                    <Link to="/my-account"><i className ="icon-shop"></i>
                                                        My Orders
                                                    </Link>
                                                </li>
                                                <li><Link to="/logout"><i className="icon-logout"></i>Logout</Link></li>
                                            </ul>
                                        </li>
                                    ) : ''
                                }

                                {
                                    !isAuthenticated ? (
                                        <li>
                                            <a 
                                                href="javascript:void(0)" 
                                                onClick={() => {this.props.openModal('UserLogin',{buttonClick: true})}}
                                            >
                                            Login
                                            </a>
                                        </li>
                                    ) : ''
                                } 
                                
                                {
                                    !isAuthenticated ? (
                                        <li>
                                            <a 
                                                href="javascript:void(0)" 
                                                onClick={() => {this.props.openModal('UserRegister',{buttonClick: true})}}
                                            >Register
                                            </a>
                                        </li>
                                    ) : ''
                                }  
                                    
                                {
                                    city ? (
                                        <li>
                                            <a 
                                                href="javascript:void(0)" 
                                                title={this.props.location.city} 
                                                className="show-submenu"
                                            >
                                            <i className="icon-location-7"></i> 
                                            {this.props.location.city.length > 20 ? `${this.props.location.city.substring(0,20)}...` : this.props.location.city} 
                                            <i className="icon-down-open-mini"></i>
                                            </a>
                                            <ul>
                                                <li>
                                                    <a 
                                                        href="javascript:void(0)"
                                                        id="change-location"
                                                    >
                                                        <input 
                                                            type="submit" 
                                                            className="btn_1" 
                                                            value="Change Location" 
                                                        />
                                                    </a>
                                                </li>
                                            </ul>
                                            
                                        </li>
                                    ) : ''
                                }
                                {
                                    this.props.order.items.length !==0 ? (
                                        <li>
                                            <a 
                                                href="javascript:void(0)"
                                                onClick={this.goToCart} 
                                                style={{color:'#ff5722',fontSize:'15px'}}
                                                title="There are items in your cart"
                                            >
                                                <i className="icon_cart_alt" style={{color:'#ff5722',fontSize:'15px'}}></i> 
                                                &nbsp; Go To Cart ({this.props.order.itemscount})
                                            </a>
                                            
                                        </li>
                                    ) : (
                                        <li>
                                            <a 
                                                href="javascript:void(0)"
                                                title="Your cart is empty"
                                            >
                                                <i className="icon_cart_alt"></i> 
                                                &nbsp; Cart
                                            </a>
                                        </li>
                                    )
                                    
                                }
                                   
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
                
                <ModalPopup 
                    isModalOpen={this.props.modal.isModalOpen} 
                    handleClose={this.handleClose}
                    modalType={this.props.modal.modalType}
                    data={this.props.modal.data}
                    selectCart={this.selectCart}
                    reOrder={this.reorderLogic}
                />
            </header>
        );
    }
}
    
const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    openModal,
    closeModal,
    toggleLoader,
    setLocation,
    reOrder
};

export default withRouter(withCookies(connect(mapStateToProps, mapDispatchToProps)(Header)));