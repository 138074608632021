import React from 'react';
import {connect} from 'react-redux';
import {withCookies} from 'react-cookie';

import {authenticateUser} from './../../actions/customerActions';
import {resetCustomer,emptyCart} from './../../actions/orderActions';
import {setLocation} from './../../actions/locationActions';

class Logout extends React.Component {
    componentDidMount() {
        const {cookies} = this.props;
        this.props.authenticateUser(false);
        this.props.history.push('/');

        cookies.remove('id',{path:'/'});
        cookies.remove('name',{path:'/'});
        cookies.remove('email',{path:'/'});
        cookies.remove('phoneno',{path:'/'});
        cookies.remove('token',{path:'/'});
        cookies.remove('gokash',{path:'/'});

        this.props.resetCustomer();
        this.props.emptyCart();
        this.props.setLocation({});
    }

    render() {
        return (
            <div></div>
        );
    }
}

const mapDispatchToProps = {
    authenticateUser,
    resetCustomer,
    emptyCart,
    setLocation
};

export default withCookies(connect(undefined,mapDispatchToProps)(Logout));