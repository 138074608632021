import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import Header from './sections/Header';
import Footer from './sections/Footer';
import MenuItemsGroup from './sections/MenuItemsGroup';
import OrderSummary from './sections/OrderSummary';
import MenuCategories from './sections/MenuCategories';
import RestaurantBanner from './sections/RestaurantBanner';
import SearchBarPopup from './sections/SearchBarPopup';

import {toggleLoader} from './../actions/loaderActions';
import {setMenu} from './../actions/menuActions';
import {setCategory} from './../actions/categoryActions';
import {setSelectedRestaurant} from './../actions/restActions';
import {setRestFoodCourt,emptyCart} from './../actions/orderActions';
import {setCuisines} from './../actions/cuisineActions';
import {toggleFoodCourt,setFoodCourtId,toggleVegOnly} from './../actions/filterActions';

import {loadJsLibraries} from './../utils/LoadLibrary';
import {fetchMenu} from './../utils/ApiLibrary';
import { fetchRestaurant,fetchCuisines } from './../utils/ApiLibrary';
 

import loaderImg from './../images/web-images/loader.gif';

class RestaurantMenu extends React.Component {

    checkIfOpen = (openinghours) => {
        let hoursArray = openinghours.split('-');
        const currentTime = moment();
        const beforeTime = moment(hoursArray[0].trim(),'hh:mm A');
        let afterTime = moment(hoursArray[1].trim(),'hh:mm A');
        
        if(afterTime.isSameOrBefore(beforeTime)){
            afterTime = afterTime.add(1,'d');
        }
        return currentTime.isBetween(beforeTime,afterTime);
    }

    componentWillMount() {
        const id = this.props.match.params.id;
        const restId = this.props.restaurant.id;
        let openinghours;

        if(id){
            if(id !== restId) {
                fetchCuisines('/gokhana/cuisine').then(result => {
                    this.props.setCuisines(result),
                    fetchRestaurant(id).then(result => {
                        if(result.data){

                            result.data.openinghours.forEach((openingHr) => {
                                if(moment().format('dddd').toLowerCase() === openingHr.day.toLowerCase()) {
                                    openinghours = `${moment(openingHr.from,['HH:mm']).format('hh:mm A')} - ${moment(openingHr.to,['HH:mm']).format('hh:mm A')}`;
                                }
                            });

                            const isOpen = this.checkIfOpen(openinghours);

                            let restaurantData = {
                                id: result.data.id,
                                name: result.data.name,
                                address: result.data.address,
                                imageUrl: result.data.imageUrl,
                                cuisines: result.data.cuisines,
                                isExist: true,
                                openinghours,
                                isOpen
                            };
                            
                            let setFoodOrderData = {
                                parentId: result.data.id,
                                restaurantname: result.data.name,
                                foodcourt: '',
                                foodcourtname: '',
                                imageUrl: result.data.imageUrl
                            };
            
                            (this.props.filters.foodcourtOnly && this.props.filters.foodcourtCB == "true") ? (
                                setFoodOrderData.foodcourt = this.props.filters.foodcourtId,
                                setFoodOrderData.foodcourtname = this.props.filters.foodcourtName
                            ) : ''
                            
                            this.props.setRestFoodCourt(setFoodOrderData);
                            this.props.setSelectedRestaurant(restaurantData);  
                            
                            fetchMenu(id).then(result => {
                                this.props.setMenu(result);
                                const cat = {
                                    id : result[0].id,
                                    name: result[0].name
                                }
                                this.props.setCategory(cat);
                                
                            });
                        } else {
                            this.props.setSelectedRestaurant({isExist: false}); 
                        }
                    })  
                })
            } 
        } else {
            this.props.history.push('/')
        };
    }

    componentDidMount() {
        loadJsLibraries();

        if(this.props.order.parentId !== this.props.match.params.id) {
            this.props.emptyCart();
        }
    }

    backToRestaurants = () => {
        this.props.history.push('/restaurants');
    }
    
    handleVegOnlyChange = (e) => {
        this.props.toggleVegOnly(e.target.checked);
    }

    handleOnScroll = (e) => {

        let trackingElement;
        let result;
        let computedPositions = [];
        let catIds = [];
        let catNames = [];
        let i = 0;
        let id;
        let name;

        const parentDivOffset = document.querySelector('#menu-items-group').getBoundingClientRect();
        
        const allElements = document.querySelectorAll('.cat_menu_items');
        const itemsLength = allElements.length;
        
        while(i < itemsLength){
            catIds.push(allElements[i].getAttribute('data-categoryId'));
            catNames.push(allElements[i].getAttribute('data-categoryName'));

            trackingElement = document.querySelector(`#${allElements[i].id}`).getBoundingClientRect();
            result = trackingElement.top - parentDivOffset.top;
            computedPositions.push(result);
            i++;
        }
        
        computedPositions.forEach((pos,index) => {
            pos > -10 && pos < 30 ? (
                id = catIds[index],
                name = catNames[index],
                this.props.setCategory({id,name})
            )
            : ''
        });
    }
    
    render() {
        if(!this.props.category.id) {
            return (<div style={{textAlign: 'center',marginTop:'200px'}}><img src={loaderImg} style={{width:'50px'}}/><h2>Loading...</h2></div>);
        }

        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header />
                <RestaurantBanner />
                    <div className="container margin_60_35">
                        <div className="row">
                            {/* Mobile view */}
                            <div className="col-sm-12" id="mobile-back-rest-button">
                                <p><a href="javascript:void(0)" className="btn_side" onClick={this.backToRestaurants}>Back to Restaurants</a></p>
                            </div>

                            <div className="col-sm-12" id="mobile-veg-only-filter">
                                <div className="row">
                                    <div className="col-xs-6">
                                        <h5>Show Veg Only</h5>
                                    </div>
                                    <div className="col-xs-6" id="mobile-veg-filter">
                                        <label className="switch">
                                            <input 
                                                type="checkbox" 
                                                onChange={(e) => this.handleVegOnlyChange(e)}
                                                {...this.props.filters.vegOnly ? {checked: true} : {}}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div id="mobile-menu-cat-button">
                                <a 
                                    href="javascript:void(0)"
                                >
                                    <i className="icon-book-open"></i>
                                </a>
                            </div>
                        
                        {
                            this.props.restaurant.isExist === false ? (
                                <h3 style={{textAlign: 'center'}}>Oops! We are unable to connect you to the restaurant at the moment.</h3>
                            ) : (
                                <div>
                                    <div id="disp-mobile-menu-cat">
                                        <MenuCategories />
                                    </div>
                                    <div id="menu-cat-overlay"></div>
                                    <MenuItemsGroup handleOnScroll={this.handleOnScroll}/>
                                    <div  className="col-md-3" id="sidebar">
                                        <OrderSummary />
                                    </div>
                                </div>
                            )
                        }
                        </div>
                    </div>
                <Footer />
            </div>
        );
    }
    
}

const mapDispatchToProps = {
    setMenu,
    setCategory,
    setSelectedRestaurant,
    setRestFoodCourt,
    setCuisines,
    emptyCart,
    toggleLoader,
    toggleFoodCourt,
    setFoodCourtId,
    toggleVegOnly
};

export default withRouter(connect(state => state, mapDispatchToProps)(RestaurantMenu));