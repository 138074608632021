export const setMobileField = (data) => ({
    type: 'SET_MOBILE_FIELD',
    data
});

export const setVerifyOTP = (data) => ({
    type: 'SET_VERIFY_OTP',
    data
});

export const setEmail = (data) => ({
    type: 'SET_EMAIL',
    data
});

export const setName = (data) => ({
    type: 'SET_NAME',
    data
});

export const toggleTac = (data) => ({
    type: 'TOGGLE_TAC',
    data
})