import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import modalReducer from './../reducers/modalReducer';
import locationReducer from './../reducers/locationReducer';
import loaderReducer from '../reducers/loaderReducer';
import cuisineReducer from '../reducers/cuisineReducer';
import restaurantReducer from '../reducers/restaurantReducer';
import foodcourtReducer from '../reducers/foodcourtReducer';
import trendingReducer from '../reducers/trendingReducer';
import popularReducer from '../reducers/popularReducer';
import newAdditionReducer from '../reducers/newAdditionReducer';
import filterReducer from '../reducers/filterReducer';
import restReducer from '../reducers/restReducer';
import menuReducer from '../reducers/menuReducer';
import categoryReducer from '../reducers/categoryReducer';
import orderReducer from '../reducers/orderReducer';
import customerReducer from './../reducers/customerReducer';
import formReducer from './../reducers/formReducer';
import paymentReducer from './../reducers/paymentReducer';
import orderHistoryReducer from './../reducers/orderHistoryReducer';
import restAutoCompleteReducer from './../reducers/restAutoCompleteReducer';

const reducers = combineReducers({
    modal: modalReducer,
    location: locationReducer,
    loader: loaderReducer,
    cuisines: cuisineReducer,
    restaurants: restaurantReducer,
    foodcourt: foodcourtReducer,
    restautocomplete: restAutoCompleteReducer,
    trending: trendingReducer,
    popular: popularReducer,
    newaddition: newAdditionReducer,
    filters: filterReducer,
    restaurant: restReducer,
    menu: menuReducer,
    category: categoryReducer,
    order: orderReducer,
    customer: customerReducer,
    formfields: formReducer,
    paymentpage: paymentReducer,
    orderHistory: orderHistoryReducer
});

const initialState = {
    modal: {
        isModalOpen : false,
        modalType: undefined,
        data: {
           buttonClick : false
        }
    },
    location: {
        city: "",
        state: "",
        latitude: "",
        longitude: "",
        error: ""
    },
    loader: {
        isOpen: false
    },
    cuisines: [],
    restaurants: [],
    foodcourt: {},
    restautocomplete: [],
    trending: [],
    popular: [],
    newaddition: [],
    filters: {
        vegOnly: false,
        distance: 50,
        cuisines: [],
        foodcourtOnly: false,
        foodcourtId: 0,
        foodcourtName:"",
        foodcourtCB:"false"
    },
    restaurant: {},
    menu: [],
    category:{},
    order: {
        entityId: "5825d66df009c4bc37fd7bae",
        items: [],
        itemscount: 0,
        totalprice: 0,
        taxamount: 0,
        taxitems: [],
        grossamount: 0,
        paidamount:0,
        paid: false,
        discountsplit: [],
        transactions: [],
        paymenttransactions:[],
        discountfromcredit: 0,
        promotion: null,
        creditdiscount: null,
        refundamounttocustomer: 0,
        restaurantcustomer: "",
        customername: "",
        customerno:"",
        parentId: "",
        imageUrl: "",
        restaurantname: "",
        foodcourt: "",
        foodcourtname: "",
        orderstatus: "requested",
        placedtime: "",
        appId: "5825d15cf009c4bc37fd7b9f",
        createdby: "",
        prepaid: true
    },
    customer : {
        isAuthenticated: false,
        updateCustomerDetails : {
            name: {
                value: '',
                isValid: false,
                error: ''
            },
            email: {
                value: '',
                isValid: false,
                error: ''
            }
        },
        selecttab: {
            orders: true,
            profile: false
        },
        selectedOrder: '',
        isUpdated: false
    },
    formfields: {
        mobile: {
            value: '',
            isValid: false,
            error: ''
        },
        verifyotp: {
            value: '',
            isValid: false,
            error: ''
        },
        name: {
            value: '',
            isValid: false,
            error: ''
        },
        email: {
            value: '',
            isValid: false,
            error: ''
        },
        tac : {
            value: false,
            error: ''
        }
    },
    paymentpage : {
        selectinfo: {
            contactdetails: false,
            paymentdetails: true
        }, 
        paymentoption: {
            error: '',
            cc: {
                isActive: true,
                cardtype: '',
                bankcode: ''
            },
            dc: {
                isActive: false,
                cardtype: '',
                bankcode: ''
            },
            nb: {
                isActive: false,
                bankcode: ''
            },
            wallet: {
                isActive:false,
                paytm: {
                    isActive: false
                }
            },
            mealvouchers : {
                isActive: false,
                sodexo: {
                    isActive: false,
                    savedcard: false
                },
                ticketrestaurant: false
            }
        },
        pgdata : {},
        paytminfo: {},
        orderHistory: []
    }

};

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['customer','order','location','cuisines','trending','popular','newaddition']
}

const persistedReducer = persistReducer(persistConfig, reducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export {store, persistor};