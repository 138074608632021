import React from 'react';
import {connect} from 'react-redux';

import {setFoodCourtId,setFoodCourtName,setCentralizedBilling} from './../../actions/filterActions';

class GridListFoodCourts extends React.Component {

    handleOnClick = (e) => {
        const id = e.currentTarget.id;
     
        this.props.setFoodCourtId(id);
    }

    render() {
        return (
            <div>
                <div className="col-md-12 col-sm-12"  className="strip_list"
                 onClick={this.handleOnClick}   id={this.props.outlet.id} 
                 name={this.props.outlet.name} 
                 data-cb={this.props.outlet.centralizedbilling}
                 style={{cursor:"pointer"}}
                >
                    <div className="col-md-12 col-sm-12" id={this.props.outlet.id} >
                        <div className="desc">
                            <div className="thumb_strip">
                                <a href="javascript:void(0)"><img src={this.props.outlet.imageUrl} alt="" /></a>
                            </div>
                            <div className="desc_text">
                            <h5 style={{marginBottom:"1px"}} id={this.props.outlet.id} >{this.props.outlet.name}</h5>

                            <div className="type">
                               
                            </div>
                            <div className="location">
                                {this.props.outlet.address}
                            </div>
                            <ul>
                                {this.props.outlet.distance ? <li>{this.props.outlet.distance}</li> : ''}
                            </ul>
                            </div>
                        </div>
                        </div>
                        </div>
                </div>
        );
    }
}

const mapDispatchToProps = {
    setFoodCourtId,
    setFoodCourtName,
    setCentralizedBilling
}
export default connect(state => state, mapDispatchToProps)(GridListFoodCourts);