import axios from 'axios';

const baseURL = 'https://api.ampcome.com/api';

export const getOrders = (id,accesstoken) =>  {
    const api = `/gokhana/restaurantcustomer/${id}/foodorder?loadrelationrecord=false&limit=10&clientapp=gokhanacustomer`;
    return axios.get(`${baseURL}${api}`,{
        headers: {'Authorization': accesstoken}
    }).then(res => res).catch(e => console.log(e));
}

export const updateCustDetails = (id,body,accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${id}`;

    return axios.post(`${baseURL}${api}`,body,{
        headers: {'Authorization': accesstoken}
    }).then(res => res).catch(e => console.log(e));
}