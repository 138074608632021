import React from 'react';

import Header from './sections/Header';
import ImageSubHeader from './sections/ImageSubHeader';
import BreadCrumb from './sections/BreadCrumb';
import ContentTwoColumn from './sections/ContentTwoColumn';
import FeaturesSixColumn from './sections/FeaturesSixColumn';
import Footer from './sections/Footer';
import FeaturesTwoColumn from './sections/FeaturesTwoColumn';

import {loadJsLibraries} from './../utils/LoadLibrary';
import aboutImg from './../images/web-images/about.jpg';

class AboutUs extends React.Component {
    
    componentWillMount() {
        loadJsLibraries();
    }

    render() {
        return (
            <div>
                <Header />
                <ImageSubHeader title="About Us" subTitle="Best in Town Food Order App" bgImg={aboutImg}/>
                <BreadCrumb />
                <ContentTwoColumn />
                <FeaturesTwoColumn />
                <FeaturesSixColumn />
                <Footer />
            </div>
        );
    }
    
}

export default AboutUs;