const formReducer = (state = [], {type,data}) => {
    switch(type) {
        case 'SET_MOBILE_FIELD':
            return {...state,mobile:data};
        case 'SET_VERIFY_OTP':
            return {...state,verifyotp:data};
        case 'SET_EMAIL':
            return {...state,email: data};
        case 'SET_NAME':
            return {...state,name: data};
        case 'TOGGLE_TAC':
            return {...state,tac:data};
        default:
            return state;
    }
}

export default formReducer;