import React from 'react';
import Loader from './../sections/Loader';

import cards from './../../images/icon1.png';
import appleicon from './../../images/apple1.png';
import androidicon from './../../images/android1.png';

export default () => {
    return (
        <div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-12">
                        <p>
                                <img src={cards} alt="" className="img-responsive" />
                            </p>
                            <h3>How can we help you?</h3>
                            Contact our customer support team if you have any further questions.<br/> We are here to help you out.<br/><br/>
                            (+91) 8884417543<br/><br/>
                            support@gokhana.com
                           
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h3>Download our mobile app</h3>
                            <p>
                            
                            <a href="https://itunes.apple.com/in/app/gokhana/id1254124599?mt=8" target="_blank">
                            <img src={appleicon} alt=""  style={{width:"150px"}}/>
                            </a>
                            <br/>
                            <br/>
                            <a href="https://play.google.com/store/apps/details?id=com.gokhana.customer&hl=en" target="_blank">
                            <img src={androidicon} alt=""  style={{width:"150px"}}/>
                           </a>
                            </p>
                            <div id="message-newsletter_2">
                            </div>
                          
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <h3>Company</h3>
                            <ul>
                                <li><a href="http://www.gokhana.com/#overviews" target="_blank">How it works</a></li>
                                <li><a href="http://www.gokhana.com/#whyus" target="_blank">Why Us</a></li>
                                <li><a href="http://www.gokhana.com/#about" target="_blank">About Us</a></li>
                                <li><a href="http://www.gokhana.com/blog.html" target="_blank">Blog</a></li>                            
                                
                                
                            </ul>
                        </div> 
                        <div className="col-md-3 col-sm-12">
                            <h3>Legal</h3>
                            <ul>                              
                                <li><a href="http://www.gokhana.com/terms.html" target="_blank">Terms & Conditions</a></li>
                                <li><a href="http://www.gokhana.com/privacy.html" target="_blank">Privacy Policy</a></li>
                                <li><a href="http://www.gokhana.com/refund.html" target="_blank">Refund Policy</a></li>
                                <li><a href="http://www.gokhana.com/faq.html" target="_blank">FAQ</a></li>
                                
                                
                            </ul>
                        </div>                      
                      
                      
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div id="social_footer">
                                <ul>
                                    <li><a href="https://www.facebook.com/TeamGoKhana/" target="_blank"><i className="icon-facebook"></i></a></li>
                                    <li><a href="https://twitter.com/TeamGoKhana"><i className="icon-twitter" target="_blank"></i></a></li>
                                    <li><a href="https://www.instagram.com/teamgokhana/"><i className="icon-instagram" target="_blank"></i></a></li>
                                    <li><a href="https://in.pinterest.com/teamgokhana/"><i className="icon-pinterest" target="_blank"></i></a></li>
                                </ul>
                                <p>
                                    © GoKhana 2018
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <Loader />            
        </div>
    );
}