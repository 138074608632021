import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import PrivateRoute from './PrivateRoute';

import HomePage from './../components/HomePage';
import AboutUs from './../components/AboutUs';
import ContactUs from './../components/ContactUs';
import PageNotFound from './../components/PageNotFound';
import RestaurantList from '../components/RestaurantList';
import FoodCourt from '../components/FoodCourt';
import RestaurantMenu from '../components/RestaurantMenu';
import UserDetails from '../components/UserDetails';
import OrderConfirmation from '../components/OrderConfirmation';
import CustomerAccount from '../components/CustomerAccount';
import Logout from '../components/sections/Logout';
import RedirectPG from '../components/sections/RedirectPG';
import OrderFail from '../components/OrderFail';
import PgResponse from '../components/sections/PgResponse';
import ScrollToTop from './../components/sections/ScrollToTop';

export default () => {
    return (
        <BrowserRouter>
            <ScrollToTop>
            <Route render={({location}) => (
                <TransitionGroup>
                    <CSSTransition key={location.key} timeout={300} classNames="fade">
                        <Switch location={location}>
                            <Route path="/" component={HomePage} exact={true}/>
                            <Route path="/about" component={AboutUs} />
                            <Route path="/contact" component={ContactUs} />
                            <Route path="/restaurants" component={RestaurantList} />
                            <Route path="/foodcourt/:id" component={FoodCourt} />
                            <Route path="/restaurant/:id" component={RestaurantMenu} />
                            <Route path="/order-confirmation" component={OrderConfirmation} />
                            <PrivateRoute path="/payment-options" component={UserDetails} />
                            <PrivateRoute path="/payment-failed" component={OrderFail} />
                            <PrivateRoute path="/my-account" component={CustomerAccount} />
                            <PrivateRoute path="/logout" component={Logout} />
                            <PrivateRoute path="/redirect-to-pg" component={RedirectPG} />
                            <PrivateRoute path="/pg-response" component={PgResponse} />
                            
                            <Route component={PageNotFound} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            )} />
            </ScrollToTop>
        </BrowserRouter>
    );
}