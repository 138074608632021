import React from 'react';
import validator from 'validator';
import {connect} from 'react-redux';
import {withCookies} from 'react-cookie';
import _ from 'lodash';

import {setVerifyOTP} from './../../actions/formActions';
import {verifyOTP,sendOTP,getGokash} from './../../utils/LoginApiLibrary';
import {getOrders} from './../../utils/CustomerLibrary';
import {toggleLoader} from './../../actions/loaderActions';
import {authenticateUser} from './../../actions/customerActions';
import {closeModal} from './../../actions/modalActions';
import {setCustomer} from './../../actions/orderActions';
import {setOrderHistory} from './../../actions/orderHistoryActions';
import {setSelectedOrder} from './../../actions/customerActions';

class VerifyUser extends React.Component {

    validate = (e) => {
        let OTP = e.target.value;
        const isValid = validator.isNumeric(OTP) && validator.isLength(OTP,{min:6,max:6});
        !isValid ? 
            this.props.setVerifyOTP({value:OTP,isValid:false,error:'Enter a valid 6 digit OTP.'}) : 
            this.props.setVerifyOTP({value:OTP,isValid:true,error:''});
    }

    componentDidMount() {
        this.props.setVerifyOTP({value:'',isValid:false,error:''});
    }

    verifyUser = (e) => {
        const {mobile,verifyotp,cookies} = this.props;
        e.preventDefault();
        
        verifyOTP(mobile.value,verifyotp.value).then(res => {
            res.data.message === 'Verified' ? (
                this.props.toggleLoader(true),
                this.props.setVerifyOTP({value:verifyotp.value,isValid:true,error:'Login success'}),
                
                //get gokash
                getGokash(res.data.user.id,res.data.user.accessToken.id).then(credit => {
                    cookies.set('gokash',credit.data.credit)
                }),
                //set the cookies
                cookies.set('id',res.data.user.id,{path:'/'}),
                cookies.set('name',_.startCase(res.data.user.name,{path:'/'})),
                cookies.set('email',res.data.user.email,{path:'/'}),
                cookies.set('phoneno',res.data.user.phoneno,{path:'/'}),
                cookies.set('token',res.data.user.accessToken.id,{path:'/'}),

                //store the data in redux store
                this.props.authenticateUser(true),
                this.setAuthUser(res.data.user.id,res.data.user.accessToken.id),
                this.props.setCustomer({
                    restaurantcustomer: res.data.user.id,
                    customername: res.data.user.name,
                    customerno: res.data.user.phoneno,
                    createdby: res.data.user.id
                }),

                setTimeout(() => {
                    this.props.closeModal(),
                    this.props.toggleLoader(false)
                },2000) 
            ) : ''
        }).catch(e => {
            e.response.data.message === 'Invalid Details' || e.response.status === 401 ? 
                this.props.setVerifyOTP({value:verifyotp.value,isValid:false,error:'Invalid OTP / OTP expired'}) : ''
        });
    }

    setAuthUser = (id,token) => {
        getOrders(id,token).then(result => this.props.setOrderHistory(result.data)),
        this.props.setSelectedOrder('')
    }

    resendOTP = () => {
        const {mobile} = this.props;
        sendOTP(mobile.value).then(res => {
            this.props.setVerifyOTP({value:'',isValid:false,error:'OTP is sent again, please wait a while...'})
        });
    }

    render() {
        const {verifyotp} = this.props;
        return (
            <form onSubmit={this.verifyUser} action="javascript:void(0)" class="popup-form" id="myLogin">
                <div class="login_icon"><i class="icon-mobile-6"></i></div>
                <h3>Verify it's you</h3>
                <p>We've sent a verification code to your mobile number.</p>
                <input 
                    type="text" 
                    class="form-control form-white" 
                    placeholder="Enter 6 digit code" 
                    onInput={this.validate}
                    {...verifyotp.error ? {style:{borderColor:'#fd0000'}} : {}}
                    {...verifyotp.error === 'Login success' ? {style:{borderColor:'#0a820f'}} : {}}
                />
                <p {...verifyotp.error === 'Login success' ? {style:{fontSize:'14px',color:'#0a820f',textAlign:'left'}} : {style:{fontSize:'14px',color:'#fd0000',textAlign:'left'}}}>{verifyotp.error}</p>
                <div class="text-left">
                    Didn't received OTP yet?<br/> Wait for a minute & click&nbsp;
                    <a 
                        href="javascript:void(0)"
                        onClick={this.resendOTP}
                    >Resend OTP</a>
                </div>
                <button 
                    type="submit" 
                    class="btn btn-submit"
                >Verify</button>
            </form>
        );
    }
}

const mapDispatchToProps = {
    setVerifyOTP,
    sendOTP,
    toggleLoader,
    authenticateUser,
    closeModal,
    setCustomer,
    setSelectedOrder,
    setOrderHistory

};

export default withCookies(connect(state => state.formfields,mapDispatchToProps)(VerifyUser));