import React from 'react';
import {connect} from 'react-redux';
import {Redirect,Link,withRouter} from 'react-router-dom';

import {setSelectedRestaurant} from './../../actions/restActions';
import {addRemoveItemFromCart,emptyCart} from './../../actions/orderActions';

class OrderSummary extends React.Component {

	state = {
		error: ''
	};

	emptyCart = () => {
		this.props.emptyCart();
	}

	handleOrderNow = () => {
		const {isOpen} = this.props.restaurant;
		if(isOpen) {
			this.props.history.push('/payment-options');
		} else {
			this.setState({error: `Oops! Restaurant is closed now. Try between ${this.props.restaurant.openinghours}`});
		}
		
	}

	getTaxAmountArray = (price,taxgroup) => {
        let taxAmount = [];
        let taxobj = {};
        taxgroup.length !== 0 ? (
            taxgroup.forEach(tax => {
                taxobj = {};
                taxobj.name = tax.name;
                if(tax.type === 'percentage') {
                    taxobj.amount = Math.round(price * tax.value * 0.01);
                } else if(tax.type === 'flat') {
                    taxobj.amount = Number(tax.value);
                }
                taxAmount = [...taxAmount,taxobj];
            })
        )
        :'';

        return taxAmount;
	}
	
	addItem = (e) => {
		const id = e.currentTarget.id;
		const menuId = e.currentTarget.attributes.getNamedItem('data-mid').value;
		let price = e.currentTarget.attributes.getNamedItem('data-price').value;
		
		const menuItem = this.getMenuItem(menuId);
		const taxGroup = menuItem.taxgroup.items;
		let taxItems = this.props.order.taxitems;
		let taxAmount = 0;
		let storeItems = [...this.props.order.items];
		let updatedItems = storeItems;
		let netPrice;
		let addTax;

		let addItemId = storeItems.find(item => {
			return item.id === id;
		});

		price = (addItemId.totalprice/addItemId.count);

		updatedItems.forEach(item => {
			item.id === addItemId.id ? (
				item.count = item.count + 1,
				item.totalprice += price,
				addTax = this.getTaxAmountArray(item.totalprice,taxGroup),
				addTax.forEach(tax => taxAmount += tax.amount),
				item.taxAmount = taxAmount
				
			) : ''
		});

		netPrice = this.billing(updatedItems);
		taxItems=this.createTaxItems(updatedItems);

		this.props.addRemoveItemFromCart(updatedItems,netPrice,taxItems);
	}

	removeItem = (e) => {
		const id = e.currentTarget.id;
		const menuId = e.currentTarget.attributes.getNamedItem('data-mid').value;
		let price = e.currentTarget.attributes.getNamedItem('data-price').value;
		
		const menuItem = this.getMenuItem(menuId);
		const taxGroup = menuItem.taxgroup.items;
		let taxItems = this.props.order.taxitems;
		let taxAmount = 0;
		let storeItems = [...this.props.order.items];
		let updatedItems = storeItems;
		let netPrice;
		let deductTax;

		let deleteItem = this.props.order.items.find(item => {
			return item.id === id;
		});

		price = (deleteItem.totalprice/deleteItem.count);
		deductTax = this.getTaxAmountArray(deleteItem.totalprice,taxGroup);

		if(deleteItem.count > 1) {
			updatedItems.forEach(item => {
				item.id === deleteItem.id ? (
					item.count = item.count - 1,
					item.totalprice -= price,
					deductTax = this.getTaxAmountArray(item.totalprice,taxGroup),
					deductTax.forEach(tax => taxAmount += tax.amount),
					item.taxAmount = taxAmount
					
				) : ''
			});

		} else {
			updatedItems = storeItems.filter(item => item.id!=deleteItem.id);
		}

		netPrice = this.billing(updatedItems);
		taxItems=this.createTaxItems(updatedItems);

		this.props.addRemoveItemFromCart(updatedItems,netPrice,taxItems);
	}

	createTaxItems = (items) => {
		let newTax = [];
		let singleItemTax; 
		items.forEach(item => {
			let taxRule = this.getMenuItem(item.restaurantmenuitem);
			
			singleItemTax = this.getTaxAmountArray(item.totalprice,taxRule.taxgroup.items);
			if(newTax.length === 0) {
				newTax = singleItemTax;
			} else {
				for(i=0; i<newTax.length; i++) {
					newTax[i].name === singleItemTax[i].name ? (newTax[i].amount += singleItemTax[i].amount) : '';
				}
			}
		});
        return newTax;
	}
	
	getTotalPrice = (price,addonitems,customeitems) => {
        if(addonitems.length !== 0) {
            addonitems.forEach(item => price+=Number(item.price));
        }

        if(customeitems.length !== 0) {
            customeitems.forEach(item => {
                (item.options.length > item.freechoicescount) ? (
                    price+=((item.options.length - item.freechoicescount) * item.price)
                ) : ''; 
            });
        }
        
        return price;
    }

	getTaxAmountArray = (price,taxgroup) => {
        let taxAmount = [];
        let taxobj = {};
        taxgroup.length !== 0 ? (
            taxgroup.forEach(tax => {
                taxobj = {};
                taxobj.name = tax.name;
                if(tax.type === 'percentage') {
                    taxobj.amount = Math.round(price * tax.value * 0.01);
                } else if(tax.type === 'flat') {
                    taxobj.amount = Number(tax.value);
                }
                taxAmount = [...taxAmount,taxobj];
            })
        )
        :'';

        return taxAmount;
	}
	
    getTaxAmount = (price,taxes) => {
        let taxAmount = 0;
        taxes.length !== 0 ? (
            taxes.forEach(tax => {
                if(tax.type === 'percentage') {
                    taxAmount += Math.round(price * tax.value * 0.01);
                } else if(tax.type === 'flat') {
                    taxAmount += Number(tax.value);
                }
            })
        )
        :'';
        return taxAmount;
    }
	billing = (items) => {
        let netPrice = {
            itemscount : 0,
            totalprice : 0,
            grossamount : 0,
            taxamount : 0
        };

        netPrice.grossamount = this.getGrossAmount(items);
		netPrice.taxamount = this.getTax(items);
		netPrice.totalprice = netPrice.grossamount + netPrice.taxamount;
		netPrice.itemscount = items.length;

        return netPrice;
	}

	getGrossAmount = (items) => {
        let amt = 0;
        items.length !==0 ? (
            items.forEach(item => amt += item.totalprice)
        ) : '';
        return amt;
    }

    getTax = (items) => {
        let amt = 0;
        items.length !==0 ? (
            items.forEach(item => amt += item.taxAmount)
        ) : '';
        return amt;
	}

	getMenuItem = (id) => {
        let menuItem;
        this.props.menu.map(category => {
			category.menuitems.length !== 0 ? (
				category.menuitems.map(item => {
					item.id === id ? menuItem = item : '';
				})
			) : (
				category.subcategories.map(subcat => {
					subcat.menuitems.map(item => {
						item.id === id ? menuItem = item : '';
					})
				})
			)
        });
        return menuItem;
	}
	
    render() {
		
		return (
			<div>
			{!this.props.restaurant ? <Redirect to="/restaurants" /> : ''}
				<div className="theiaStickySidebar">
					<div id="cart_box" >
						<h3>Your order <i className="icon_cart_alt pull-right"></i></h3>
						<table className="table table_summary">
						<tbody>
						{
							this.props.order.items.length !== 0 ? (
								this.props.order.items.map(item => {
									return (
										<tr>
											<td title={item.menuitemname}>
												<a 
													href="javascript:void(0)" 
													className="remove_item" 
													id={item.id}
													data-mid={item.restaurantmenuitem}
													data-price={item.totalprice}
													onClick={this.removeItem}
													>
													<i className="icon_minus_alt"></i>
												</a> 
												&nbsp;<strong style={{verticalAlign:'text-bottom'}}>{item.count}</strong>&nbsp;
												<a 
													href="javascript:void(0)" 
													className="remove_item" 
													id={item.id}
													data-mid={item.restaurantmenuitem}
													data-price={item.totalprice}
													onClick={this.addItem}
													>
													<i className="icon_plus_alt"></i>
												</a>
												&nbsp;
												{item.menuitemname.length > 20 ? `${item.menuitemname.substring(0,20)}...` : item.menuitemname}
												{
													item.features.length !== 0 ? (
														item.features.map(option => {
															return <div title={option.name}><i className="icon-check-1"></i> {option.name.length > 18 ? `${option.name.substring(0,18)}...`: option.name}</div>
														})
													)
													: <div></div>
												}
												{
													item.customeitems.length !== 0 ? (
														item.customeitems.map(option => {
															return (
																<div title={option.categoryname}>
																<i className="icon-check-1"></i> 
																{option.categoryname.length > 18 ? `${option.categoryname.substring(0,18)}...`: option.categoryname}
																	{
																		option.options.map(opt => {
																			return <div className="sub-options" title={opt.name}><i className="icon-minus"></i> {opt.name}</div>
																		})
																	}
																</div>
															)
														})
													)
													: <div></div>
												}
												
												</td>
											<td>
												<strong className="pull-right"> &#8377; {item.totalprice}</strong>
											</td>
										</tr>
									)
								})
							) : <h4 style={{textAlign:'center'}}>Your cart is empty</h4>
						}
						</tbody>
						</table>
						<hr />
						{/*}
						<div className="row" id="options_2">
							<div className="col-lg-6 col-md-12 col-sm-12 col-xs-6">
								<label><input type="radio" value="" checked name="option_2" className="icheck" />Delivery</label>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12 col-xs-6">
								<label><input type="radio" value="" name="option_2" className="icheck" />Take Away</label>
							</div>
						</div>
					*/}
						
						<table className="table table_summary">
							<tbody>
								<tr>
									<td>
										Subtotal <span className="pull-right">&#8377; {this.props.order.grossamount}</span>
									</td>
								</tr>
								{
									this.props.order.items.length !== 0 ? (
										this.props.order.taxitems.map(item => {
											return (
												<tr>
													<td>
														{item.name}
														<span className="pull-right">
															&#8377; {item.amount}
														</span>
													</td>
												</tr>		
											)
										})
									) : ''
								}
									
								<tr>
									<td className="total">
										TOTAL <span className="pull-right">&#8377; {this.props.order.totalprice}</span>
									</td>
								</tr>
							</tbody>
						</table>
						{
							this.props.order.items.length !== 0 ? (
								<div>
									<hr />
									<p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{this.state.error}</p>
									<a href="javascript:void(0)" className="btn_full" onClick={this.handleOrderNow}>Order Now</a>
									<a href="javascript:void(0)" className="btn_full"  onClick={this.emptyCart}>Empty Cart</a>									
								</div>
							) : ''
						}
						<a href="javascript:void(0)" id="close-cart-window"><i className="icon-cancel-circle-2"></i></a>

					</div>
					</div>
				</div>
		);
	}
}

const mapDispatchToProps = {
	setSelectedRestaurant,
	addRemoveItemFromCart,
	emptyCart
};

export default withRouter(connect(state => state, mapDispatchToProps)(OrderSummary));