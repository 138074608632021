import React from 'react';
import {connect} from 'react-redux';

import veg from './../../images/web-images/veg.png';
import nonveg from './../../images/web-images/nonveg.png';

class Mitem extends React.Component {
    render() {
        const {item} = this.props;
        return (
            <tr>
                <td style={{"width":"70%"}}>
                    <figure className="thumb_menu_list"><img src={item.isVeg ? 'veg' : 'nonveg'} alt="thumb" /></figure>
                    <h5>{item.name}</h5>
                    <img src={item.isVeg ? veg : nonveg} width="20px"/>
                </td>
                <td>
                    <strong>&#8377; {item.price}</strong>
                </td>
                <td className="options">
                    <a 
                        href="javascript:void(0)" 
                        className="dropdown-toggle" 
                        data-toggle="dropdown" 
                        aria-expanded="true"
                        id={item.id}
                        onClick={this.props.handleDispAddons}
                    ><i className="icon_plus_alt2"></i>
                    </a>
                    {
                        !!this.props.category.menuitem ? (
                            this.props.category.menuitem.id === item.id ? (
                                <div className="dropdown dropdown-options open">
                                    <div className="dropdown-menu">
                                        {
                                            this.props.category.menuitem.addonitems.length !==0 ? (
                                                <div>
                                                    <h5>Select Add-ons</h5>
                                                    {
                                                        this.props.category.menuitem.addonitems.map(addonitem => {
                                                            return (<label title={addonitem.name}>
                                                                        <input 
                                                                            type = {this.props.category.menuitem.addonchoicetype === 'singlechoice' ? 'radio' : 'checkbox'}
                                                                            value={addonitem.name}
                                                                            onClick={this.props.handleMenuAddonItem}
                                                                            name="selectoption"
                                                                            {...addonitem.included ? {checked: true} : {}}
                                                                        />{addonitem.name.length >=15 ? `${addonitem.name.substring(0,15)}...` : addonitem.name}
                                                                        {addonitem.price !==0 ? <span>+ &#8377; {addonitem.price}</span> : ''}
                                                                    </label>)
                                                        })
                                                    }
                                                    
                                                </div>
                                            ) : ''
                                        }
                                        {
                                            this.props.category.menuitem.customeitems.length !==0 ? (
                                                <div>
                                                    {
                                                        this.props.category.menuitem.customeitems.map(customeitem => {
                                                        return (
                                                            <div>
                                                                <h5>{customeitem.categoryname}</h5>
                                                                {
                                                                    customeitem.options.map(option => {
                                                                        return (
                                                                            option.active ? (
                                                                                <label title={option.name}>
                                                                                    <input 
                                                                                        type="checkbox" 
                                                                                        id={option.id}
                                                                                        onClick={this.props.handleMenuCustomItem}
                                                                                        {...option.included ? {checked: true} : {}}
                                                                                    />{option.name}
                                                                                    {option.price !==0 ? <span>+ &#8377;{option.price}</span> : ''}
                                                                                </label>
                                                                            ) : ''
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )

                                                        })
                                                    }
                                                    
                                                </div>
                                            ) : ''
                                        }
                                        <a 
                                            href="javascript:void(0)" 
                                            className="add_to_basket"
                                            id={item.id}
                                            onClick={this.props.addToCart}
                                        >Add to cart</a>
                                        <a 
                                            href="javascript:void(0)" 
                                            className="add_to_basket"
                                            id={item.id}
                                            onClick={this.props.cancel}
                                        >Cancel</a>
                                    </div>
                                </div>
                            ) : ''
                            
                        ) : ''
                    }
                </td>
                </tr>
        );
    }
}

export default connect(state => state)(Mitem);