import React from 'react';
import validator from 'validator';
import {connect} from 'react-redux';

import {setMobileField,setEmail,setName,toggleTac} from './../../actions/formActions';
import {openModal} from './../../actions/modalActions';
import {sendOTP,loginUser} from './../../utils/LoginApiLibrary';

class RegisterForm extends React.Component {

    validateFullName = (name) => {
        const fullName = name.split(" ");
        let isValid = false;

        fullName.length <=3 ? (
            isValid = validator.isAlpha(fullName[0]) && validator.isLength(fullName[0],{min:3,max:30}),
            fullName[1] && isValid ? isValid = validator.isAlpha(fullName[1]) : '',
            fullName[2] && isValid ? isValid = validator.isAlpha(fullName[2]) : ''
        ) : '';

        return isValid;
    }

    validateMobile = (e) => {
        let mobileNum = e.target.value;
        const isValid = validator.isMobilePhone(mobileNum,'en-IN');
        !isValid ? 
            this.props.setMobileField({value:mobileNum,isValid:false,error:'Enter a valid 10 digit mobile number.'}) : 
            this.props.setMobileField({value:mobileNum,isValid:true,error:''});
    }

    validateName = (e) => {
        let name = e.target.value;
        const isValid = this.validateFullName(name);
        !isValid ? 
            this.props.setName({value:name,isValid:false,error:'Enter a valid name.'}) : 
            this.props.setName({value:name,isValid:true,error:''});
    }

    validateEmail = (e) => {
        let email = e.target.value;
        const isValid = validator.isEmail(email);
        !isValid ? 
            this.props.setEmail({value:email,isValid:false,error:'Enter a valid email address.'}) : 
            this.props.setEmail({value:email,isValid:true,error:''});
    }

    handleCheckbox = (e) => {
        let isChecked = e.target.checked;
        isChecked ? this.props.toggleTac({value: true,error:''}) : this.props.toggleTac({value: false,error:''});
    }

    validate = (e) => {
        e.preventDefault();
        const {mobile,name,email,tac} = this.props;
        !mobile.value ? 
            this.props.setMobileField({value:mobile.value,isValid:false,error:'Enter a valid 10 digit mobile number.'}) : '';
        !email.value ? 
            this.props.setEmail({value:email,isValid:false,error:'Enter a valid email address.'}) : '';
        !name.value ? 
            this.props.setName({value:name,isValid:false,error:'Enter a valid name.'}) : '';
        !tac.value ? 
            this.props.toggleTac({value: false,error: 'Please accept terms and conditions first.'}) 
            : '';
    }

    register = () => {
        const {mobile,name,email,tac} = this.props;
        (mobile.isValid && email.isValid && name.isValid && tac.value) ? (
            loginUser(mobile.value,email.value,name.value).then(data => {
                sendOTP(mobile.value).then(res => {
                    this.props.openModal('VerifyUser',{buttonClick: false});
                });
            })
        ) : ''
    }

    componentDidMount() {
        this.props.setMobileField({value:'',isValid:false,error:''});
        this.props.setEmail({value:'',isValid:false,error:''});
        this.props.setName({value:'',isValid:false,error:''});
        this.props.toggleTac({value: false, error: ''});
    }

    render() {
        return (
            <form onSubmit={this.validate} class="popup-form" id="myRegister">
                <div className="login_icon"><i className="icon_lock_alt"></i></div>
                <input 
                    type="text" 
                    className="form-control form-white" 
                    placeholder="Contact No." 
                    onInput={this.validateMobile}
                    {...this.props.mobile.error ? {style:{borderColor:'#fd0000'}} : {}}
                />
                <p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{this.props.mobile.error}</p>
                <input 
                    type="email" 
                    className="form-control form-white" 
                    placeholder="Email" 
                    onInput={this.validateEmail}
                    {...this.props.email.error ? {style:{borderColor:'#fd0000'}} : {}}
                />
                <p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{this.props.email.error}</p>
                <input 
                    type="text" 
                    className="form-control form-white" 
                    placeholder="Name" 
                    onInput={this.validateName} 
                    {...this.props.name.error ? {style:{borderColor:'#fd0000'}} : {}}
                />
                <p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{this.props.name.error}</p>

                <div id="pass-info" className="clearfix"></div>
                <div className="checkbox-holder text-left">
                    <div className="checkbox">
                        <input 
                            type="checkbox" 
                            value="accept_2" 
                            id="check_2" 
                            name="check_2"
                            onChange={(e) => this.handleCheckbox(e)}
                            {...this.props.tac.value ? {checked:true} : {}} 
                        />
                        <label for="check_2"><span>I Agree to the <strong>Terms &amp; Conditions</strong></span></label>
                        <p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{this.props.tac.error}</p>
                    </div>
                </div>
                <button type="submit" className="btn btn-submit" onClick={this.register}>Register</button>
            </form>
        );
    }
}

const mapDispatchToProps = {
    setMobileField,
    setEmail,
    setName,
    toggleTac,
    openModal
}

export default connect(state => state.formfields,mapDispatchToProps)(RegisterForm);
