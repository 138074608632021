import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {selectInfo} from './../../actions/paymentActions';

class CheckoutSidebar extends React.Component {

    setActive = (data) => {
        data === 'contact' ? 
            this.props.selectInfo({contactdetails:true,paymentdetails:false}) :
            this.props.selectInfo({contactdetails:false,paymentdetails:true}) 
    }

    backToMenu = () => {
        const id = this.props.order.parentId;
        this.props.history.push(`/restaurant/${id}`);
    }

    render() {
        const {contactdetails,paymentdetails} = this.props.paymentpage.selectinfo;
        return (
            <div className="col-md-3">
            <p><a href="javascript:void(0)" class="btn_side" onClick={this.backToMenu}>Back to Menu</a></p>
                <div className="box_style_1">
                    <ul id="cat_nav">
                        <li>
                            <a 
                                href="javascript:void(0)"
                                onClick={() => this.setActive('contact')} 
                                {...contactdetails ? {className:'active'} : {}}
                            >Contact Details</a>
                        </li>
                        <li>
                            <a 
                                href="javascript:void(0)" 
                                onClick={() => this.setActive('payment')} 
                                {...paymentdetails ? {className:'active'} : {}}
                            >Payment Details</a>
                        </li>
                    </ul>
                </div>
                
                <div className="box_style_2 hidden-xs" id="help">
                    <i className="icon_lifesaver"></i>
                    <h4>Need <span>Help?</span></h4>
                    <a href="tel://+918884417543" className="phone">+918884417543</a>
                    <small>Monday to Friday 9.00am - 7.30pm</small>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    selectInfo
};

export default withRouter(connect(state => state,mapDispatchToProps)(CheckoutSidebar));