import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import uuidv4 from 'uuid/v4';

import {setMenuItem,toggleMenuCustomItem,toggleMenuAddonItem} from './../../actions/categoryActions';
import {addItemToCart,updateItemCart} from './../../actions/orderActions';

import Mitem from './Mitem';

class MenuItems extends React.Component {  
    netPrice = {};
    
    getMenuItem = (id) => {
        let menu = [...this.props.menu];
        let menuItem = {};
        menu.forEach(category => {
            category.menuitems.length !== 0 ? (
                category.menuitems.forEach(item => {
                    item.id === id ? menuItem = {...item} : '';
                })
            ) : (
                category.subcategories.forEach(subcat => {
                    subcat.menuitems.forEach(item => {
                        item.id === id ? menuItem = {...item} : '';
                    })
                })
            )
        });
        return menuItem;
    }

    handleDispAddons = (e) => {
        let menuItems = {};
        let newAddonItems = [];
        let newAddonItem = {};
        let toBeIncluded = true;
        let newCustomeItems = [];
        let newOptions = [];

        let mi = this.getMenuItem(e.currentTarget.id);
        mi.addonchoicetype === 'singlechoice' ? (
            mi.addonitems.forEach(it => {
                newAddonItem = {...it};
                newAddonItem.included = (toBeIncluded && it.included) ? true : false;
                newAddonItems = [...newAddonItems,newAddonItem];
                toBeIncluded = false;
            })
        ) : ''

        mi.customeitems.length !==0 ? (
            mi.customeitems.forEach(item => {
                newOptions = [];
                item.options.forEach(option => {
                    newOptions = [...newOptions,{...option}];
                });
                newCustomeItems = [...newCustomeItems,{...item,options: newOptions}];
            })
        ) : ''
    
        menuItems = {...mi,addonitems: newAddonItems, customeitems: newCustomeItems};
        this.props.setMenuItem(menuItems);
    }

    handleMenuCustomItem = (e) => {
        const id = e.currentTarget.id;
        this.props.toggleMenuCustomItem(id);
    }

    handleMenuAddonItem = (e) => {
        const value = e.currentTarget.value;
        this.props.toggleMenuAddonItem(value);
    }

    addToCart = (e) => {
        const store = this.props;
        const id = e.currentTarget.id;
        let itemFromStore = undefined;
        let menuItem,itemFound;
        let taxItems;
        let item = this.cmi(id);
        let billing = this.billing(item);
        let taxes = [...store.order.taxitems];

        let taxgroup = store.category.menuitem.taxgroup.items;
        let price = item.totalprice;

        let existItem = this.props.order.items.filter(item => item.restaurantmenuitem === id);

        if(existItem.length === 0) {
            taxItems = this.createTaxItems(taxgroup,taxes,price);
            this.props.addItemToCart(item,billing,taxItems);
        } else {
            itemFound = this.checkItemAlredyExists(existItem,item);
            billing = {};

            if(itemFound) {
                itemFromStore = {...itemFound};
                menuItem = this.getMenuItem(itemFromStore.restaurantmenuitem);

                //create menu item
                itemFromStore.count += item.count;
                itemFromStore.totalprice += item.totalprice;
                itemFromStore.taxAmount = this.getTaxAmount(itemFromStore.totalprice,menuItem.taxgroup.items);

                //update billing data
                billing.grossamount = (this.getGrossAmount(store.order.items) - itemFound.totalprice) + itemFromStore.totalprice;
                billing.taxamount = (this.getTax(store.order.items) - itemFound.taxAmount) + itemFromStore.taxAmount;
                billing.totalprice = billing.grossamount + billing.taxamount;
                billing.itemscount = store.order.itemscount;
                
                //get tax items data
                taxItems = this.createTaxItems(taxgroup,taxes,itemFromStore.totalprice);
                let deductTax = this.getTaxAmountArray(itemFound.totalprice,taxgroup);
               
                for(i=0; i<taxItems.length; i++) {
                    taxItems[i].name === deductTax[i].name ? (taxItems[i].amount -= deductTax[i].amount) : '';
                }

                //update it to cart
                this.props.updateItemCart(itemFromStore,billing,taxItems);
            } else {
                let billing = this.billing(item);
                taxItems = this.createTaxItems(taxgroup,taxes,price);
                this.props.addItemToCart(item,billing,taxItems);
            }

        }
        this.props.setMenuItem({});
    }

    cmi = (id) => {
        let item = {};
        let menuitem = this.props.category.menuitem;
        let restaurant = this.props.restaurant;
        
        menuitem.id === id ? (
            item.restaurantmenuitem = menuitem.id,
            item.menuitemname = menuitem.name,
            item.restaurant = restaurant.id,
            item.restaurantname = restaurant.name,
            item.restaurantcustomer = "589b060ed62bca35d8b958a2",
            item.count = 1,
            item.features = menuitem.addonitems.length !== 0 ? this.getAddonItems(menuitem.addonitems) : [],
            item.customeitems = menuitem.customeitems.length !== 0 ? this.getCustomeItems(menuitem.customeitems) : [],
            item.totalprice = this.getTotalPrice(Number(menuitem.price),item.features,item.customeitems),
            item.taxAmount = this.getTaxAmount(item.totalprice,menuitem.taxgroup.items),
            item.id = uuidv4(),
            item.status = 'requested',
            item.placedtime = moment().toISOString()

        ) : '';

        return item;
    }

    billing = (item) => {
        const store = this.props;
        let netPrice = {
            itemscount : 0,
            totalprice : 0,
            grossamount : 0,
            taxamount : 0
        };

        netPrice.itemscount = store.order.items.length + 1;
        netPrice.grossamount = this.getGrossAmount(store.order.items) + Number(item.totalprice);
        netPrice.taxamount = this.getTax(store.order.items) + Number(item.taxAmount);
        netPrice.totalprice = netPrice.grossamount + netPrice.taxamount;
        return netPrice;
    }

    getGrossAmount = (items) => {
        let amt = 0;
        items.length !==0 ? (
            items.forEach(item => amt += item.totalprice)
        ) : '';
        return amt;
    }

    getTax = (items) => {
        let amt = 0;
        items.length !==0 ? (
            items.forEach(item => amt += item.taxAmount)
        ) : '';
        return amt;
    }

    createTaxItems = (items,taxitems,price) => {
        let taxItems = [];
        let taxItem = {};
        let txAmt = 0;

        items.forEach(tax => {
            let matchFound = taxitems.find(taxitem => tax.name === taxitem.name);
            if(tax.type === 'percentage'){txAmt = Math.round(Number(price) * tax.value * 0.01)};
            if(tax.type === 'flat') {txAmt = tax.value};
            
            taxItem ={};
            !!matchFound ? (
                taxItem = matchFound,
                taxItem.amount = taxItem.amount + txAmt
            ) : (
                taxItem.name = tax.name,
                taxItem.amount = txAmt
            ); 
            taxItems = [...taxItems, taxItem];
        });
        return taxItems;
    }
    
    checkItemAlredyExists = (existItem,newItem) => {

        let isSID = false;
        let isSF = false;
        let isSC = false;
        let isSame = false;
        let foundItem = undefined;
        
        existItem.forEach(item => {
            let eF = item.features;
            let nF = newItem.features;
            let eC = item.customeitems;
            let nC = newItem.customeitems;

            if(item.restaurantmenuitem === newItem.restaurantmenuitem ) {
                isSID = true;
                if(eF.length === nF.length) {
                    isSF = true;
                    for(i=0;i<eF.length;i++) {
                        isSF = eF[i].name === nF[i].name ? true : false;
                    }
                }
                if(eC.length === nC.length) {
                    isSC = true;
                    for(let i=0;i<eC.length;i++) {
                        isSC = eC[i].categoryname === nC[i].categoryname ? true : false;
                        if(eC[i].options.length === nC[i].options.length){
                            for(let j=0; j<eC[i].options.length;j++) {
                                isSC = eC[i].options[j].id === nC[i].options[j].id ? true : false;
                            }
                        } else {isSC = false}
                    }
                }
            }

            if(isSID && isSF && isSC) {foundItem = item}
        });
        return foundItem;
    }

    getTotalPrice = (price,addonitems,customeitems) => {
        if(addonitems.length !== 0) {
            addonitems.forEach(item => price+=Number(item.price));
        }

        if(customeitems.length !== 0) {
            customeitems.forEach(item => {
                (item.options.length > item.freechoicescount) ? (
                    price+=((item.options.length - item.freechoicescount) * item.price)
                ) : ''; 
            });
        }
        
        return price;
    }

    getTaxAmount = (price,taxes) => {
        let taxAmount = 0;
        taxes.length !== 0 ? (
            taxes.forEach(tax => {
                if(tax.type === 'percentage') {
                    taxAmount += Math.round(price * tax.value * 0.01);
                } else if(tax.type === 'flat') {
                    taxAmount += Number(tax.value);
                }
            })
        )
        :'';
        return taxAmount;
    }

    getTaxAmountArray = (price,taxgroup) => {
        let taxAmount = [];
        let taxobj = {};
        taxgroup.length !== 0 ? (
            taxgroup.forEach(tax => {
                taxobj = {};
                taxobj.name = tax.name;
                if(tax.type === 'percentage') {
                    taxobj.amount = Math.round(price * tax.value * 0.01);
                } else if(tax.type === 'flat') {
                    taxobj.amount = Number(tax.value);
                }
                taxAmount = [...taxAmount,taxobj];
            })
        )
        :'';

        return taxAmount;
    }

    getAddonItems = (addons) => {
        let addonItems = [];
        let addonItem = {};

        addons.forEach(addon => {
            addonItem = {};
            addon.included ? (
                addonItem.name = addon.name,
                addonItem.price = addon.price,
                addonItem.isIncluded = addon.included,
                addonItems = [...addonItems, addonItem]
            
            ) : '';
        });

        return addonItems;
    }

    getCustomeItems = (customes) => {
        let customeItems = [];
        let customeItem = {};

        customes.forEach(item => {
            customeItem = {};
            customeItem = {...item};
            customeItem.options = this.getOptions(item.options);
            customeItem.totalprice = this.getCustomeTotalPrice(customeItem.freechoicescount,customeItem.options,item.price);

            (customeItem.options.length !== 0) ? 
                customeItems = [...customeItems, customeItem]
            : '';
        });


        return customeItems;
    }

    getCustomeTotalPrice = (choices,options,price) => {
        return options.length > choices ? (
            ((options.length-choices) * price)
        ) : 0;
    }

    getOptions = (options) => {
        let newOptions = [];
        options.forEach(option => {
            option.included ? newOptions = [...newOptions, option] : ''
        });

        return newOptions;
    }

    cancel = (e) => {
        this.props.setMenuItem({});
    }

    filterItems = (items) => {
        let filteredItems = items.filter(item => {
            return item.isVeg === true;
        });

        return filteredItems;
    }

    
    render() {
        const showVegOnly = this.props.filters.vegOnly;
        const {cat} = this.props;
        const category = cat;
        let menuitems;
        return (
            <div>
                {
                    (category.menuitems.length !== 0) ? (
                        menuitems = showVegOnly ? this.filterItems(category.menuitems) : category.menuitems,
                        <table className="table table-striped cart-list">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Price</th>
                                    <th>Order</th>
                                </tr>
                            </thead>

                            <tbody>
                            {    
                                menuitems.length !== 0 ? (
                                    menuitems.map(item => {
                                        return(
                                            <Mitem 
                                                item={item} 
                                                handleDispAddons={this.handleDispAddons}
                                                handleMenuAddonItem={this.handleMenuAddonItem}
                                                handleMenuCustomItem={this.handleMenuCustomItem}
                                                addToCart={this.addToCart}
                                                cancel={this.cancel}
                                            />   
                                        )
                                    })
                                ) : <tr><td colSpan="3">No Veg Items</td></tr>
                            }   
                            </tbody>
   
                        </table>
                    ) : (
                        category.subcategories.map(subcat => {
                            return (
                                menuitems = showVegOnly ? this.filterItems(subcat.menuitems) : subcat.menuitems,
                                <div>
                                <h3 className="nomargin_top">{subcat.name}</h3>
                                <table className="table table-striped cart-list">
                                    <thead>
                                        <tr>
                                            <th>Item</th>
                                            <th>Price</th>
                                            <th>Order</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {    
                                        menuitems.length !== 0 ? (
                                            menuitems.map(item => {
                                                return(
                                                    <Mitem 
                                                        item={item} 
                                                        handleDispAddons={this.handleDispAddons}
                                                        handleMenuAddonItem={this.handleMenuAddonItem}
                                                        handleMenuCustomItem={this.handleMenuCustomItem}
                                                        addToCart={this.addToCart}
                                                        cancel={this.cancel}
                                                    />   
                                                )
                                            })
                                        ) : <tr><td colSpan="3">No Veg Items</td></tr>
                                    }   
                                    </tbody>
                                </table>
                            </div>
                            )
                        })
                    )
                }  
            </div>
        );
    }
}

const mapDispatchToProps = {
    setMenuItem,
    toggleMenuCustomItem,
    toggleMenuAddonItem,
    addItemToCart,
    updateItemCart
};

export default connect(state => state, mapDispatchToProps)(MenuItems);