import React from 'react';
import qs from 'query-string';
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import moment from 'moment';

import {emptyCart,setPaymentTxn,setOrderData,setOrderId} from './../../actions/orderActions';
import {getResponse,createFoodCourt} from './../../utils/PaymentGateway';
import loader from './../../images/web-images/loading.gif';

class PgResponse extends React.Component{
    
    componentDidMount() {
        const response = qs.parse(location.search);
        const {cookies} = this.props.cookies;
        const order = {...this.props.order};
        delete order.orderResponse;
        const placedTime = moment().toISOString();
        const orderData = {
            placedTime,
            paidamount : order.paidamount
        };

        getResponse(response.txn,cookies.token).then(res => {
            let txnData = {
                paidamount: res.data.amount,
                transactionid: res.data.id,
                paymentgateway: res.data.paymentgateway,
                paymenttype: res.data.paymenttype,
                paidtime: orderData.placedTime,
                bankname: res.data.bankname || ''
            };

            (res.data.status === 'AUTHORIZED' || 
            res.data.status === 'TXN_SUCCESS' || 
            res.data.status === 'CLEARED' || 
            res.data.status === 'CLEARANCE_INITIATED') ? (
                
                //Set transaction details in order
                this.props.setPaymentTxn(txnData),
                order.paymenttransactions = [txnData],

                this.props.setOrderData(orderData),
                order.placedtime = orderData.placedTime,
                order.paidamount = order.totalprice,
                order.paid = true,

                //create the food order
                createFoodCourt(order.parentId,order,cookies.token).then(result => {
                    result.data.orderid ? (
                        this.props.emptyCart(),
                        this.props.setOrderId(result.data),
                        this.props.history.push('/order-confirmation')
                    ) : ''
                }).catch(e => console.log(e))           
            ) : this.props.history.push('/payment-failed')
        });
    }
    
    render() {
        return (
            <div style={{textAlign:'center'}}>
                <img src={loader} width="120px"/>
                <h4>Please wait while we are processing your payment...</h4>
                <h5>(Do not press the refresh or back button)</h5>
            </div>
        );  
    }
}
const mapDispatchToProps = {emptyCart,setPaymentTxn,setOrderData,createFoodCourt,setOrderId};

export default withCookies(withRouter(connect(state => state,mapDispatchToProps)(PgResponse)));