import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import GridListRestaurant from './GridListRestaurant';

import {setFoodCourtId,setFoodCourtName,setCentralizedBilling} from './../../actions/filterActions';

class DisplayRestaurants extends React.Component {

    handleOnClick = () => {
        this.props.setFoodCourtId(0);
        this.props.setFoodCourtName('');
        this.props.setCentralizedBilling('false');
        this.props.history.push('/restaurants');
    }

    checkIfOpen = (openinghours) => {
        let hoursArray = openinghours.split('-');
        const currentTime = moment();
        const beforeTime = moment(hoursArray[0].trim(),'hh:mm A');
        let afterTime = moment(hoursArray[1].trim(),'hh:mm A');
        
        if(afterTime.isSameOrBefore(beforeTime)){
            afterTime = afterTime.add(1,'d');
        }
        return currentTime.isBetween(beforeTime,afterTime);
    }

    render() {
        return (
            <div className="col-md-9">
            {this.props.filters.foodcourtOnly ? 
            <a className="btn_full" onClick={this.handleOnClick}>Back to FoodCourts</a> : ''}
                {
                    (this.props.restaurants.length !==0) ? 
                    (this.props.restaurants.map((restaurant) => {
                        let outlets = [...restaurant.outlets];
                        outlets.forEach((outlet) => {
                            outlet.isOpen = outlet.openinghours ? this.checkIfOpen(outlet.openinghours) : '';
                        });
                        return this.props.filters.foodcourtId ? (
                            (outlets.length !== 0 && restaurant.id === this.props.filters.foodcourtId) ? (
                                outlets.map(outlet => (<GridListRestaurant outlet={outlet}/>))
                            ) : ''
                        ) : (
                            outlets.length !== 0 && restaurant.entityName === 'general' ? (
                                outlets.map(outlet => (<GridListRestaurant outlet={outlet}/>))
                            ) : ''
                        )
                        
                    })) : <div className="error-msg"><h5>Oops! No Restaurants Found</h5></div>
                }

            </div>
        );
    }
}

const mapDispatchToProps = {
    setFoodCourtId,
    setFoodCourtName,
    setCentralizedBilling
};

export default withRouter(connect(state => state, mapDispatchToProps)(DisplayRestaurants));