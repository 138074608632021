import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';

import Header from './sections/Header';
import Footer from './sections/Footer';
import ImageSubHeader from './sections/ImageSubHeader';

import {loadJsLibraries} from './../utils/LoadLibrary';
import {openModal} from './../actions/modalActions';

import orderImg from './../images/web-images/orderconfirm.jpg';

class OrderConfirmation extends React.Component {
    
    componentWillMount() {
        loadJsLibraries();
    }

    render() {
        const {orderResponse} = this.props.order;
        return (
            <div>
                {
                    !orderResponse ? <Redirect to = '/' /> : ''
                }
                <Header />
                <ImageSubHeader title="Order Confirmed" showOrder={true} orderId={3} bgImg={orderImg} />
                
                <div class="container margin_60_35">
                    <div class="row">
                        <div class="col-md-offset-3 col-md-6">
                            <div class="box_style_2">
                                <h2 class="inner">Order confirmed!</h2>
                                <div id="confirm">
                                    <i class="icon_check_alt2"></i>
                                    <h3>Thank you!</h3>
                                    <p>
                                        Thank you placing an order at GoKhana. We strive hard to deliver the best food and make dining simple. We appreciate your support. Please find the confirmation of your order details below.
                                    </p>
                                </div>
                                <h4>Summary</h4>
                                <table class="table table-striped nomargin">
                                <tbody>
                                {
                                    orderResponse ? (
                                        orderResponse.items.length !== 0 ? (
                                            orderResponse.items.map(item => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <strong>{item.count}x</strong>&nbsp;
                                                            {item.menuitemname.length > 70 ? `${item.menuitemname.substring(0,70)}...` : item.menuitemname}
                                                            {
                                                                item.features.length !== 0 ? (
                                                                    item.features.map(option => {
                                                                        return <div title={option.name}><i className="icon-check-1"></i> {option.name.length > 18 ? `${option.name.substring(0,18)}...`: option.name}</div>
                                                                    })
                                                                )
                                                                : <div></div>
                                                            }
                                                            {
                                                                item.customeitems.length !== 0 ? (
                                                                    item.customeitems.map(option => {
                                                                        return (
                                                                            <div title={option.categoryname}>
                                                                            <i className="icon-check-1"></i> 
                                                                            {option.categoryname.length > 50 ? `${option.categoryname.substring(0,50)}...`: option.categoryname}
                                                                                {
                                                                                    option.options.map(opt => {
                                                                                        return <div className="sub-options" title={opt.name}><i className="icon-minus"></i> {opt.name}</div>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                )
                                                                : <div></div>
                                                            }
                                                            
                                                            </td>
                                                        <td>
                                                            <strong className="pull-right"> &#8377; {item.totalprice}</strong>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : ''
                                    ): ''
                                }
                                {
                                    orderResponse ? (
                                        orderResponse.taxamount !== 0 ? (
                                            <tr>
                                                <td>
                                                    TAXES
                                                </td>
                                                <td>
                                                    <strong class="pull-right">&#8377; {orderResponse.taxamount}</strong>
                                                </td>
                                            </tr>
                                        ) : ''
                                    ) : ''
                                }
                                
                                {
                                    orderResponse ? (
                                        orderResponse.items.length !== 0 ? (
                                            <tr>
                                                <td class="total_confirm">
                                                    TOTAL
                                                </td>
                                                <td class="total_confirm">
                                                    <span class="pull-right">&#8377; {orderResponse.totalprice}</span>
                                                </td>
                                            </tr>
                                        ) : ''
                                    ) : ''
                                }
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
    
                <Footer />
            </div>
        );
    }
    
}
const mapDispatchToProps = {
    openModal
};

export default connect(state => state,mapDispatchToProps)(OrderConfirmation);