import React from 'react';
import {connect} from 'react-redux';

import Header from './sections/Header';
import Footer from './sections/Footer';
import Filters from './sections/Filters';
import DisplayRestaurants from './sections/DisplayRestaurants';
import FoodcourtBanner from './sections/FoodcourtBanner';
import SearchBarPopup from './sections/SearchBarPopup';

import {setRestaurants} from './../actions/restaurantActions';
import {setFoodCourtId,toggleFoodCourt,setCentralizedBilling,setFoodCourtName} from './../actions/filterActions';
import {setCuisines} from './../actions/cuisineActions';
import {setFoodcourt} from './../actions/foodcourtActions';

import {loadJsLibraries} from './../utils/LoadLibrary';
import {fetchFoodcourt,fetchCuisines} from './../utils/ApiLibrary';

import loaderImg from './../images/web-images/loader.gif';

class Foodcourt extends React.Component {

    componentDidMount() {
        loadJsLibraries();
    }
    
    componentWillMount() {
        const id = this.props.match.params.id;
        fetchFoodcourt(id).then(res => {
            if(res.length !== 0) {
                fetchCuisines('/gokhana/cuisine').then(result => this.props.setCuisines(result));
                this.props.setFoodcourt(res);
                this.props.toggleFoodCourt(true);
                this.props.setFoodCourtId(id);
                this.props.setFoodCourtName(res.name);
                this.props.setCentralizedBilling(res.centralizedbilling);
            } else {
                this.props.setFoodCourtName('invalid');
            }
        });
    }
    

    render(){
        if(!this.props.filters.foodcourtId && this.props.filters.foodcourtName !== 'invalid') {
            return (<div style={{textAlign: 'center',marginTop:'200px'}}><img src={loaderImg} style={{width:'50px'}}/><h2>Loading...</h2></div>);
        }
        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header />
                <FoodcourtBanner />
                <div className="container margin_60_35">
                    <div className="row">
                    {
                        this.props.filters.foodcourtName === 'invalid' ? (
                            <h3 style={{textAlign: 'center'}}>Oops! We are unable to connect you to the restaurant at the moment.</h3>
                        ) : (
                            <div>
                                <Filters />
                                <DisplayRestaurants /> 
                            </div>
                        )
                    }  
                    </div>
                </div>
    
                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps  = {
    setRestaurants,
    setFoodCourtId,
    setFoodCourtName,
    setCentralizedBilling,
    toggleFoodCourt,
    setCuisines,
    setFoodcourt
};

export default connect(state => state,mapDispatchToProps)(Foodcourt);