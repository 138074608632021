import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import {setSelectedOrder} from './../../actions/customerActions';
import {reOrder} from './../../actions/orderActions';
import {openModal,closeModal} from './../../actions/modalActions';

class Order extends React.Component {

	viewDetail = (e) => {
		const id = e.target.id;
		this.props.setSelectedOrder(id);
	}

	reorder = (e) => {
		const id = e.target.id;
		const length = this.props.order.items.length;

		if(!length) {
			this.reorderLogic();
		} else {
			this.props.openModal('reOrder',{buttonClick: true,orderid: id});
		}
	}

	reorderLogic = () => {
		const {singleOrder} = this.props;
		let reorderData = _.omit(singleOrder,['createdAt','id','lastupdatedby','orderid','ordertype','updatedAt']);
		reorderData.paidamount = 0;
		reorderData.paid = false;
		reorderData.paymenttransactions = [];
		reorderData.placedtime = '';
		this.props.reOrder(reorderData);
		this.props.history.push(`/restaurant/${reorderData.parentId}`);
	}

    render() {
		const {singleOrder} = this.props;
        return (
            <div class="strip_list wow fadeIn" data-wow-delay="0.1s">
				<div class="row">
					<div class="col-md-9 col-sm-9">
						<div class="desc">
							<div class="thumb_strip">
								<a href="javascript:void(0)"><img src={singleOrder.imageUrl} alt="" /></a>
							</div>
							<div className="desc_text">
							<h3>{singleOrder.restaurantname}</h3>
								<div class="type">
									Order  #{singleOrder.orderid}
								</div>
								<div class="location">
									Total Paid - &#8377; {singleOrder.paidamount} | <span class="opening"> Order placed at {moment(singleOrder.placedtime).format('ddd, MMM Do YYYY, h:mm:ss a')}</span>
								</div>
								<ul>
								{
									singleOrder.items.map(item => {
										return <li><i class="icon_check_alt2 ok"></i>&nbsp;&nbsp; {item.menuitemname} x{item.count}</li>
									})
								}
								</ul>
							</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3">
						<div class="go_to">
							<div>
								<a class="btn_full" href="javascript:void(0)" id={singleOrder.orderid} onClick={this.viewDetail}>View Details</a>
								<a class="btn_full_outline" href="javascript:void(0)" id={singleOrder.orderid} onClick={this.reorder}><i class="icon-right"></i> Reorder</a>
							</div>
						</div>
					</div>
					
				</div>
				{
					this.props.customer.selectedOrder === singleOrder.orderid ? (
						<div className="view-details">
							<table className="table table_summary">
								<tbody>
								{
									singleOrder.items.map(item => {
										return (
											<tr>
												<td title={item.menuitemname}>
													<strong>{item.count}x</strong>&nbsp;
													{item.menuitemname}
													{
														item.features.length !== 0 ? (
															item.features.map(option => {
																return <div title={option.name}><i className="icon-check-1"></i> {option.name}</div>
															})
														)
														: <div></div>
													}
													{
														item.customeitems.length !== 0 ? (
															item.customeitems.map(option => {
																return (
																	<div title={option.categoryname}>
																	<i className="icon-check-1"></i> 
																	{option.categoryname}
																		{
																			option.options.map(opt => {
																				return <div className="sub-options" title={opt.name}><i className="icon-minus"></i> {opt.name}</div>
																			})
																		}
																	</div>
																)
															})
														)
														: <div></div>
													}
													</td>
												<td>
													<strong className="pull-right"> &#8377; {item.totalprice}</strong>
												</td>
											</tr>
										)
									})
								}
								
								</tbody>
							</table>			
							<hr />
							<table className="table table_summary">
								<tbody>
									<tr>
										<td>
											Subtotal <span className="pull-right">&#8377; {singleOrder.grossamount}</span>
										</td>
									</tr>
									{
										singleOrder.items.length !== 0 ? (
											singleOrder.taxitems.map(item => {
												return (
													<tr>
														<td>
															{item.name}
															<span className="pull-right">
																&#8377; {item.amount}
															</span>
														</td>
													</tr>		
												)
											})
										) : ''
									}
										
									<tr>
										<td className="total">
											TOTAL <span className="pull-right">&#8377; {singleOrder.totalprice}</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					) : ''
				}
			</div>
        );
    }
}

const mapDispatchToProps = {
	setSelectedOrder,
	reOrder,
	openModal,
	closeModal
};

export default withRouter(connect(state => state,mapDispatchToProps)(Order));
