import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {setLocation,setLocationError} from './../../actions/locationActions';
import {toggleLoader} from './../../actions/loaderActions';

import locationImg from './../../images/location.ico';

class SearchLocationBar extends React.Component {
    location = {}
    
    componentWillMount = () => {
        this.props.setLocationError("");
    }
    

    componentDidMount() {
        let autocomplete = document.getElementById('autocomplete');
        let GoogleMapsApi = new google.maps.places.Autocomplete((autocomplete), {
            types: '(regions)',
            componentRestrictions: {country: 'in'}
        });

        google.maps.event.addListener(GoogleMapsApi, 'place_changed', () => {
            this.location = {};
            const place = GoogleMapsApi.getPlace();
            this.location.latitude = place.geometry.location.lat();
            this.location.longitude = place.geometry.location.lng();
            let city=[];
            place.address_components.forEach((address) => {
                if(address.types.includes('street_number')){
                    city.push(address.short_name);
                } else if(address.types.includes('route')){
                    city.push(address.short_name);
                } else if(address.types.includes('sublocality')) {
                    city.push(address.short_name);
                } else if(address.types.includes('locality')) {
                    city.push(address.short_name);
                } else if(address.types.includes('administrative_area_level_1')) {
                    this.location.state = address.short_name;
                }
            });
            this.location.city = city.join(', ');
            this.props.setLocation(this.location);
            this.props.toggleLoader(true);
            
            setTimeout(() => {
                this.props.history.push('/restaurants');
            },1000);            
        });
     
    }

    locateMe = () => {
        let error = "",location = {}, addressComponent = '';

        if(!navigator.geolocation){
            error = "Your browser does not support Geolocation";
            return;
        }

        navigator.geolocation.getCurrentPosition((position) => {
            let geocoder = new google.maps.Geocoder;
            var latlng = {lat: position.coords.latitude, lng: position.coords.longitude};
            geocoder.geocode({location: latlng}, (results) => {
                addressComponent = results[0].address_components;
                
                let city = [];
                addressComponent.forEach((address) => {
                    if(address.types.includes('street_number')){
                        city.push(address.short_name);
                    } else if(address.types.includes('route')){
                        city.push(address.short_name);
                    } else if(address.types.includes('sublocality')) {
                        city.push(address.short_name);
                    } else if(address.types.includes('locality')) {
                        city.push(address.short_name);
                    } else if(address.types.includes('administrative_area_level_1')) {
                        location.state = address.short_name;
                    }
                });

                location.latitude = latlng.lat;
                location.longitude = latlng.lng;
                location.city = city.join(', ');

                this.props.setLocation(location);
                this.props.setLocationError(false);
                this.props.toggleLoader(true);
                setTimeout(() => {
                    this.props.history.push('/restaurants');
                },1000); 

            });
        }, (e => this.props.setLocationError(true)));
    }

    render() {
        return (
            <form autoComplete="off" action="#" style={{"textAlign":"left"}}>
           
                <div id="custom-search-input" >
                    <div className="input-group ">
                    
                        <input 
                            type="text" 
                            className=" search-query" 
                            placeholder="Enter your location"
                            id="autocomplete"
                        />
                        <span className="input-group-btn">
                            <input 
                                type="submit" 
                                className="btn_search" 
                                value="submit" 
                                onClick={e => e.preventDefault()}
                            />
                            <a 
                                href="javascript:void(0)" 
                                className="btn_location" 
                                title="Locate Me"
                                onClick={this.locateMe}
                            >
                                <img src={locationImg} />
                            </a>
                        </span>
                    </div>
                </div>
                {
                    this.props.location.error ? (
                        <p id="custom-search-error">You have blocked Gokhana from tracking your location. To use this, change your location settings in browser.</p>
                    ) : ''
                }
                
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    setLocation,
    toggleLoader,
    setLocationError
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchLocationBar));