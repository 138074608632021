import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Header from './sections/Header';
import Footer from './sections/Footer';
import ImageHeader from './sections/ImageHeader';
import SearchBarPopup from './sections/SearchBarPopup';

import {setLocation} from './../actions/locationActions';
import {toggleLoader} from './../actions/loaderActions';
import {setPopular} from './../actions/popularActions';
import {setNewAddition} from './../actions/newAdditionActions';
import {setCuisines} from './../actions/cuisineActions';

import {loadJsLibraries} from './../utils/LoadLibrary';
import {fetchRestaurants, fetchCuisines} from './../utils/ApiLibrary';

class HomePage extends React.Component {

    category;
    limit = 6;
    cuisineAPI = '/gokhana/cuisine';
    restaurantAPI = '/gokhana/restaurant/categorized';

    componentWillMount() {
        const {city} = this.props.location;
        city ? this.props.history.push('/restaurants') : '';
        loadJsLibraries();

        fetchCuisines(this.cuisineAPI).then(result => this.props.setCuisines(result));

        //fetch popular restaurants
        // this.category = 'popular';
        // fetchRestaurants(
        //     this.restaurantAPI,
        //     '',
        //     '',
        //     '',
        //     this.category,
        //     this.limit,
        //     [],
        //     false
        // ).then(result => this.props.setPopular(result));

        //fetch popular restaurants
        // this.category = 'newaddition';
        // fetchRestaurants(
        //     this.restaurantAPI,
        //     '',
        //     '',
        //     '',
        //     this.category,
        //     this.limit,
        //     [],
        //     false
        // ).then(result => this.props.setNewAddition(result));
    }

    componentDidMount() {

        if(!this.props.location.city) {
            let error = "",location = {}, addressComponent = '';

            if(!navigator.geolocation){
                error = "Your browser does not support Geolocation";
                return;
            }

            navigator.geolocation.getCurrentPosition((position) => {
                let geocoder = new google.maps.Geocoder;
                var latlng = {lat: position.coords.latitude, lng: position.coords.longitude};
                geocoder.geocode({location: latlng}, (results) => {
                    addressComponent = results[0].address_components;
                    
                    let city = [];
                    addressComponent.forEach((address) => {
                        if(address.types.includes('street_number')){
                            city.push(address.short_name);
                        } else if(address.types.includes('route')){
                            city.push(address.short_name);
                        } else if(address.types.includes('sublocality')) {
                            city.push(address.short_name);
                        } else if(address.types.includes('locality')) {
                            city.push(address.short_name);
                        } else if(address.types.includes('administrative_area_level_1')) {
                            location.state = address.short_name;
                        }
                    });

                    location.latitude = latlng.lat;
                    location.longitude = latlng.lng;
                    location.city = city.join(', ');

                    this.props.setLocation(location);
                    this.props.toggleLoader(true);
                    setTimeout(() => {
                        this.props.history.push('/restaurants');
                    },1000); 

                });
            }, (e => console.log(e)));
        }
    }

    render() {
        return (
            <div>
                <Header />
                <ImageHeader/>             
                <Footer />
            </div>
        );
    }  
}


const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    setLocation,
    toggleLoader,
    setPopular,
    setNewAddition,
    setCuisines
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));