import React from 'react';
import {connect} from 'react-redux';
import {withRouter,Redirect,Link} from 'react-router-dom';

import Header from './sections/Header';
import Footer from './sections/Footer';
import Filters from './sections/Filters';
import DisplayRestaurants from './sections/DisplayRestaurants';

import {toggleLoader} from './../actions/loaderActions';
import {setRestaurants} from './../actions/restaurantActions';
import {setTrending} from './../actions/trendingActions';
import {setRestData} from './../actions/restAutoCompleteActions';

import {loadJsLibraries} from './../utils/LoadLibrary';
import {fetchRestaurants} from './../utils/ApiLibrary';

import DisplayFoodCourts from './sections/DisplayFoodCourts';
import RestAutocomplete from './sections/RestAutocomplete';
import SearchBarPopup from './sections/SearchBarPopup';

class RestaurantList extends React.Component {
    lat = this.props.location.latitude;
    lng = this.props.location.longitude;
    // lat = 12.9699;
    // lng = 77.6499;
    maxDistance = 50;
    category;
    limit = 100;
    cuisineIds = this.props.filters.cuisines || [];
    outletTypes = this.props.filters.foodcourtOnly;
    vegOnly = this.props.filters.vegOnly;

    restaurantAPI = '/gokhana/restaurant/categorized';
    
    cuisines = [];
   
    componentWillMount() {
        loadJsLibraries();

        if(!this.props.location.latitude && !this.props.location.longitude) {
            this.props.history.push('/');
        } 
        this.outletTypes = this.props.filters.foodcourtOnly;
        //fetch nearby restaurants
        this.category = 'nearby';

        fetchRestaurants(
            this.restaurantAPI,
            this.lat,
            this.lng,
            this.maxDistance,
            this.category,
            this.limit,
            this.cuisineIds,
            this.outletTypes,
            this.vegOnly
        ).then(result => {
            this.props.setRestaurants(result);
            this.props.setRestData(this.filterRestData(result));
        });

        //fetch trending restaurants
        // this.category = 'trending';
        // this.limit = 6;

        // fetchRestaurants(
        //     this.restaurantAPI,
        //     '',
        //     '',
        //     '',
        //     this.category,
        //     this.limit,
        //     [],
        //     false
        // ).then(result => this.props.setTrending(result));
        
    }

    filterRestData = (data) => {
        let newRestData = [];

        data.forEach(type => {
            type.entityName === 'general' ? (
                type.outlets !== 0 ? (
                    type.outlets.forEach(outlet => {
                        newRestData = [...newRestData, {id: outlet.id, name: outlet.name}];
                    })
                ) : ''
            ) : ''
        });

        return newRestData;
    }

    componentDidMount() {

        setTimeout(() => {
            this.props.toggleLoader(false);   
        },2000);
        
    }   

    render(){
        const {restautocomplete} = this.props;
        const foodcourtURL = `/foodcourt/${this.props.filters.foodcourtId}`;
        
        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header />
                <RestAutocomplete data={restautocomplete}/>
                <div className="container margin_60_35">
                    <div className="row">
                        <Filters />
                        {
                            this.props.filters.foodcourtOnly ? 
                            (this.props.filters.foodcourtId ? <Redirect to={foodcourtURL} /> : <DisplayFoodCourts />)
                            : <DisplayRestaurants />
                        }    
                       
                    </div>
                </div>
    
                <Footer />
            </div>
        );
    }
}

const mapDispatchToProps  = {
    toggleLoader,
    setRestaurants,
    setTrending,
    setRestData
};

export default withRouter(connect(state => state,mapDispatchToProps)(RestaurantList));