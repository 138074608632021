import React from 'react';
import {connect} from 'react-redux';

import LoginForm from './LoginForm';
import {loginUser,sendOTP} from './../../utils/LoginApiLibrary';
import {closeModal,openModal} from './../../actions/modalActions';

class Login extends React.Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    login = () => {
        const mobileNum = this.props.value;
        loginUser(mobileNum).then(data => {
            sendOTP(mobileNum).then(res => {
                this.verifyUser();
            });
        });
    };

    showRegisterForm = () => {
        this.props.openModal('UserRegister',{buttonClick: false});
    }

    verifyUser = () => {
        this.props.openModal('VerifyUser',{buttonClick: false});
    }

    render() {
        return (
            <LoginForm 
                action = {this.login}
                showRegisterForm = {this.showRegisterForm}
                verifyUser = {this.verifyUser}
            />
        );
    }
}

const mapDispatchToProps = {
    closeModal,
    openModal
}

export default connect(state => state.formfields.mobile,mapDispatchToProps)(Login);