import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {toggleLoader} from './../../actions/loaderActions';

import loader from './../../images/web-images/loading.gif';

class RedirectPG extends React.Component {
    inputFields = [];
    componentWillMount() {
        const {pgdata} = this.props;
        !pgdata.redirectUrl ? this.props.history.push('/payment-options') : '';
    }

    componentDidMount() {
        const {pgdata,paytminfo} = this.props;
        this.props.toggleLoader(false);
        
        for(var key in paytminfo) {
            this.inputFields.push(<input type='hidden' name={key} value={paytminfo[key]} />);
        }

        setTimeout(() => {
            pgdata.pg === 'paytm' ? document.payment.submit() : '';
            pgdata.pg === 'sodexo' ? window.location.assign(pgdata.redirectUrl) : '';
        }, 1200);
    }
    
    render() {
        const {pgdata} = this.props;
        return (
            <div style={{textAlign:'center'}}>
                <img src={loader} width="120px"/>
                <h4>Please wait while we are redirecting you to the payment page...</h4>
                <form method="post" action={pgdata.redirectUrl} name="payment">
                    {
                        this.inputFields.length !==0  ? (
                            this.inputFields.map(field => {
                                return field
                            })
                        ) : ''
                    }
             
                </form>
            </div>
        );
    }
}

export default withRouter(connect(state => state.paymentpage,{toggleLoader})(RedirectPG));