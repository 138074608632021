import React from 'react';
import validator from 'validator';
import {connect} from 'react-redux';

import {setMobileField} from './../../actions/formActions';

class LoginForm extends React.Component {

    validate = (e) => {
        let mobileNum = e.target.value;
        const isValid = validator.isMobilePhone(mobileNum,'en-IN');
        !isValid ? 
            this.props.setMobileField({value:mobileNum,isValid:false,error:'Enter a valid 10 digit mobile number.'}) : 
            this.props.setMobileField({value:mobileNum,isValid:true,error:''});
    }

    componentDidMount() {
        this.props.setMobileField({value:'',isValid:false,error:''});
    }

    handleLogin = () => {
        const isValid = this.props.isValid;

        !isValid ? this.props.setMobileField({value:'',isValid:false,error:'Enter a valid 10 digit mobile number.'}) : 
        this.props.action();
    }
    
    render() {
        const {action, showRegisterForm} = this.props;

        return (
            <form action="javascript:void(0)" class="popup-form" id="myLogin">
                <div class="login_icon"><i class="icon_lock_alt"></i></div>
                <input 
                    type="text"
                    id="mobilenumber" 
                    class="form-control form-white" 
                    placeholder="Contact Number" 
                    onInput={this.validate}
                    {...this.props.error ? {style:{borderColor:'#fd0000'}} : {}}
                />
                <p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{this.props.error}</p>
                
                <div class="text-left">
                    <a 
                        href="javascript:void(0)"
                        onClick={showRegisterForm}
                    >Dont have account? Register here.</a>
                </div>
                <button 
                    type="submit" 
                    class="btn btn-submit"
                    onClick={this.handleLogin}
                >Login</button>
            </form>
        );
    }
}

const mapDispatchToProps = {
    setMobileField
};

export default connect(state => state.formfields.mobile,mapDispatchToProps)(LoginForm);