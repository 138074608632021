import React from 'react';
import Autosuggest from 'react-autosuggest';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {setMenu} from './../../actions/menuActions';
import {setCategory} from './../../actions/categoryActions';
import {setSelectedRestaurant} from './../../actions/restActions';
import {setRestFoodCourt,emptyCart} from './../../actions/orderActions';
import {setCuisines} from './../../actions/cuisineActions';
import {openModal} from './../../actions/modalActions';

import { fetchRestaurant,fetchCuisines } from './../../utils/ApiLibrary';
import {fetchMenu} from './../../utils/ApiLibrary';

class RestAutocomplete extends React.Component {

    state = {
        value: '',
        suggestions: []
    };
    
    restaurants = [];

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : this.restaurants.filter(lang =>
            lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    getSuggestionValue = suggestion => suggestion.name;

    renderSuggestion = suggestion => (
        <div>
            {suggestion.name}
        </div>
    );

    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    onSuggestionSelected = (event,{suggestion}) => {
        this.handleOnChange(suggestion.id);
    }

    handleOnChange = (id) => {
        {
            fetchCuisines('/gokhana/cuisine').then(result => {
                this.props.setCuisines(result),
                fetchRestaurant(id).then(result => {
                    if(result.data){
                        let restaurantData = {
                            id: result.data.id,
                            name: result.data.name,
                            address: result.data.address,
                            imageUrl: result.data.imageUrl,
                            cuisines: result.data.cuisines,
                            isExist: true
                        };
                        
                        let setFoodOrderData = {
                            parentId: result.data.id,
                            restaurantname: result.data.name,
                            foodcourt: '',
                            foodcourtname: '',
                            imageUrl: result.data.imageUrl
                        };
        
                        (this.props.filters.foodcourtOnly && this.props.filters.foodcourtCB == "true") ? (
                            setFoodOrderData.foodcourt = this.props.filters.foodcourtId,
                            setFoodOrderData.foodcourtname = this.props.filters.foodcourtName
                        ) : ''
                        
                        
                        fetchMenu(id).then(result => {
                            this.props.setMenu(result);
                            const cat = {
                                id : result[0].id,
                                name: result[0].name
                            }
                            this.props.setCategory(cat);
                            console.log('id',id);
                            console.log('parentId',this.props.order.parentId);

                            this.props.order.items.length === 0 ? (
                                this.props.history.push(`/restaurant/${id}`)
                            ) : (
                                id !== this.props.order.parentId ? 
                                    this.props.openModal('cartNotEmpty',{buttonClick: true,currentSelection:id,prevSelection:this.props.order.parentId})
                                : this.props.history.push(`/restaurant/${id}`)
                            )
                            
                        });
                    } else {
                        this.props.setSelectedRestaurant({isExist: false}); 
                    }
                    this.props.setRestFoodCourt(setFoodOrderData);
                    this.props.setSelectedRestaurant(restaurantData);
                })  
            });

        }
    }

    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: 'Search your favorite restaurant',
            value,
            onChange: this.onChange
        };
        this.restaurants = this.props.data;

        return (
            <section style={{"marginTop":"50px","backgroundColor":"#f9f9f9","borderBottom":"1px solid #ddd","borderTop":"1px solid #ddd","padding":"30px"}}>
                <div  className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={inputProps}
                                onSuggestionSelected={this.onSuggestionSelected}
                            />
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapDispatchToProps = {
    setMenu,
    setCategory,
    setSelectedRestaurant,
    setRestFoodCourt,
    setCuisines,
    emptyCart,
    openModal
};

export default withRouter(connect(state => state,mapDispatchToProps)(RestAutocomplete));