import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Header from './sections/Header';
import Footer from './sections/Footer';
import FinalOrderSummary from './sections/FinalOrderSummary';
import ImageSubHeader from './sections/ImageSubHeader';
import OrderUserInfo from './sections/OrderUserInfo';
import SearchBarPopup from './sections/SearchBarPopup';

import {openModal} from './../actions/modalActions';

import {loadJsLibraries} from './../utils/LoadLibrary';
import orderImg from './../images/web-images/placeorder.jpg';
import OrderPaymentInfo from './sections/OrderPaymentInfo';
import CheckoutSidebar from './sections/CheckoutSidebar';

class UserDetails extends React.Component {

    componentWillMount() {
        const {items} = this.props.order;
        if(items.length === 0) {
            this.props.history.push('/');
        }

        const {action} = this.props;        
        loadJsLibraries();
        action && action === 'login' ? this.props.openModal('UserLogin',{buttonClick: false}) : '';
    }
    render() {
        const {contactdetails} = this.props.paymentpage.selectinfo;
        const {modal} = this.props;
        let orderId = contactdetails? 1 : 2;
        return (
            <div>
                <div id="overlay"></div>
                <SearchBarPopup />
                <Header />
                <ImageSubHeader title="Place your order" showOrder={true} orderId={orderId} bgImg={orderImg} />
                <div className="container margin_60_35">
                    <div className="row">
                        <CheckoutSidebar />
                        {contactdetails ? <OrderUserInfo /> : <OrderPaymentInfo />} 
                        <FinalOrderSummary />
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
    
}

const mapDispatchToProps = {
    openModal
}

export default withRouter(connect(state => state,mapDispatchToProps)(UserDetails));