import React from 'react';
import Modal from 'react-modal';

import Login from './Login';
import RegisterForm from './RegisterForm';
import VerifyUser from './VerifyUser';
import FilterOptions from './FilterOptions';

class ModalPopup extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    
    render() {
        return (
            <div>
                <Modal 
                    isOpen={this.props.isModalOpen}
                    contentLabel = "Common Modal"
                    className="Modal"
                    overlayClassName = "Overlay"
                    closeTimeoutMS={200}

                >
                <div class="modal-dialog">
                    <div class="modal-content modal-popup">
                        <a 
                            href="javascript:void(0)" 
                            class="close-link" 
                            onClick={this.props.handleClose}
                        ><i class="icon_close_alt2"></i>
                        </a>
                       
                        {this.props.modalType === 'UserLogin' ? <Login /> : <div></div>}    
                        {this.props.modalType === 'UserRegister' ? <RegisterForm /> : <div></div>}    
                        {this.props.modalType === 'VerifyUser' ? <VerifyUser /> : <div></div>}
                        {this.props.modalType === 'Filters' ? <FilterOptions /> : <div></div>}
                        {
                            this.props.modalType === 'cartNotEmpty' ? (
                            <div>
                                <i className="icon-warning-empty" style={{fontSize:'100px'}}></i>
                                <h3>Your cart has food order from another restaurant.</h3> <br/><br/>
                                <button onClick={this.props.toCart} className="btn_full">Finish the Order in Cart</button>
                                <button onClick={this.props.newSelection} className="btn_full">Continue with Current Selection</button>
                            </div>
                            )
                            : <div></div>
                        }  
                        {
                            this.props.modalType === 'reOrder' ? (
                            <div>
                                <i className="icon-warning-empty" style={{fontSize:'100px'}}></i>
                                <h3>Your cart has food order from another restaurant.</h3> <br/><br/>
                                <button onClick={this.props.selectCart} className="btn_full">Finish the Order in Cart</button>
                                <button onClick={this.props.reOrder} className="btn_full">Continue with Current Selection</button>
                            </div>
                            )
                            : <div></div>
                        }    
                    </div>
                </div> 
                
                </Modal>
            </div>
        );
    }
}

export default ModalPopup;