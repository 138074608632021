import React from 'react';
import {connect} from 'react-redux';
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';

import {setSelectedRestaurant} from './../../actions/restActions';
import {removeItemFromCart,emptyCart} from './../../actions/orderActions';
import {setError,setPgData,setPaytmInfo} from './../../actions/paymentActions';
import {toggleLoader} from './../../actions/loaderActions';

import {generateTxn,getSavedCard} from './../../utils/PaymentGateway';

class FinalOrderSummary extends React.Component {
	sodexo;
	// async componentDidMount() {
	// 	const {cookies} = this.props.cookies;
	// 	const accesstoken = cookies.token;
		
	// }
	
    getSelected = () => {
        const {cc,dc,nb,wallet,mealvouchers} = this.props.paymentpage.paymentoption;
		let selectedOption;
		
		if(cc.isActive && cc.bankcode) {
			selectedOption = {paymentgateway:'paytm',paymentmode: 'credit-card',...cc};
		} else if(dc.isActive && dc.bankcode) {
			selectedOption = {paymentgateway:'paytm',paymentmode: 'debit-card',...dc};
		} else if(nb.isActive && nb.bankcode) {
			selectedOption = {paymentgateway:'paytm',paymentmode: 'net-banking',...nb};
		} else if(wallet.isActive && wallet.paytm.isActive) {
			selectedOption = {paymentgateway:'paytm',paymentmode: 'paytm-cash',...wallet};
		} else if(mealvouchers.isActive && mealvouchers.sodexo.isActive) {
			selectedOption = {paymentgateway: 'sodexo',...mealvouchers.sodexo};
		} else if(mealvouchers.ticketrestaurant) {
			selectedOption = {paymentgateway:'paytm',paymentmode: 'ticketrestaurant'};
		}
		return selectedOption;
    }

    goToPG = async () => {
		const isSelected = this.getSelected();
		const {cookies} = this.props.cookies;
		const accesstoken = cookies.token;

		const cardData = await getSavedCard(cookies.id,accesstoken);
		this.sodexo = cardData.data.sodexo;

		if(!isSelected){
            this.props.setError('Please select payment option...');
        } else {
			let paytminfo = {};
			let pgdata = {};

			const body = {
				customer: cookies.id,
				amount: this.props.order.totalprice,
				paymentgateway: isSelected.paymentgateway,
				channel: 'browser',
				customer_mobile: cookies.phoneno,
				customer_email: cookies.email,
				callbackurl: 'https://gokhanaweb.ampcome.com/pg-response'
			};
			
			if(isSelected.paymentgateway === 'paytm') { 
				body.paymentmodePreselected = true; 
				isSelected.bankcode ? body.bankname = isSelected.bankcode : '';
				
			}
			if(isSelected.paymentmode) { body.paymentmode = isSelected.paymentmode;}
			
			if(isSelected.paymentgateway === 'sodexo' && isSelected.savedcard) {
				this.sodexo.length !==0 ? (
					body.sourceId = this.sodexo[0].sourceId
				) : ''
			}
			generateTxn(body,accesstoken).then(result => {
				if(isSelected.paymentgateway === 'paytm'){
					paytminfo = result.data.paytminfo;
					pgdata.pg = 'paytm';
				} else if (isSelected.paymentgateway === 'sodexo') {
					paytminfo = {};
					pgdata.pg = 'sodexo';
				}
				pgdata.redirectUrl = result.data.redirectUrl;

				this.props.setPaytmInfo(paytminfo);
				this.props.setPgData(pgdata);

				this.props.toggleLoader(true);
				setTimeout(() => {
					this.props.history.push('/redirect-to-pg');
				},500);

			});
        }   
    }

    render() {
		
		return (
			<div>
				<div className="col-md-3" id="sidebar">
					<div className="theiaStickySidebar">

						<div id="cart_box" >
							<h3>Order Summary <i className="icon_cart_alt pull-right"></i></h3>
							<table className="table table_summary">
							<tbody>
							{
								this.props.order.items.length !== 0 ? (
									this.props.order.items.map(item => {
										return (
											<tr>
												<td title={item.menuitemname}>
													<strong>{item.count}x</strong>&nbsp;
													{item.menuitemname.length > 16 ? `${item.menuitemname.substring(0,16)}...` : item.menuitemname}
													{
														item.features.length !== 0 ? (
															item.features.map(option => {
																return <div title={option.name}><i className="icon-check-1"></i> {option.name.length > 18 ? `${option.name.substring(0,18)}...`: option.name}</div>
															})
														)
														: <div></div>
													}
													{
														item.customeitems.length !== 0 ? (
															item.customeitems.map(option => {
																return (
																	<div title={option.categoryname}>
																	<i className="icon-check-1"></i> 
																	{option.categoryname.length > 18 ? `${option.categoryname.substring(0,18)}...`: option.categoryname}
																		{
																			option.options.map(opt => {
																				return <div className="sub-options" title={opt.name}><i className="icon-minus"></i> {opt.name}</div>
																			})
																		}
																	</div>
																)
															})
														)
														: <div></div>
													}
													
													</td>
												<td>
													<strong className="pull-right"> &#8377; {item.totalprice}</strong>
												</td>
											</tr>
										)
									})
								) : <h4 style={{textAlign:'center'}}>Your cart is empty</h4>
							}
							</tbody>
							</table>
							<hr />
							<table className="table table_summary">
							<tbody>
							<tr>
								<td>
									Subtotal <span className="pull-right">&#8377; {this.props.order.grossamount}</span>
								</td>
							</tr>
							{
								this.props.order.items.length !== 0 ? (
									this.props.order.taxitems.map(item => {
										return (
											<tr>
												<td>
													{item.name}
													<span className="pull-right">
														&#8377; {item.amount}
													</span>
												</td>
											</tr>		
										)
									})
								) : ''
							}
								
							<tr>
								<td className="total">
									TOTAL <span className="pull-right">&#8377; {this.props.order.totalprice}</span>
								</td>
							</tr>
							</tbody>
							</table>
							
							{
								this.props.order.items.length !== 0 ? (
									<div>
										<hr />
										<p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{this.props.paymentpage.paymentoption.error}</p>
										<a href="javascript:void(0)" className="btn_full" onClick={this.goToPG}>Pay Now</a>
									</div>
								) : ''
							}

						</div>
						</div>
					</div>
						
					<div className="col-md-12">
						<p style={{fontSize:'14px',color:'#fd0000',textAlign:'left'}}>{this.props.paymentpage.paymentoption.error}</p>
						<a href="javascript:void(0)" id="mobile-pay-now" className="btn_full" onClick={this.goToPG}>Pay Now</a>
					</div>
				</div>
		);
	}
}

const mapDispatchToProps = {
	setSelectedRestaurant,
	removeItemFromCart,
    emptyCart,
	setError,
	setPgData,
	toggleLoader,
	setPaytmInfo
};

export default withRouter(withCookies(connect(state => state, mapDispatchToProps)(FinalOrderSummary)));