import React from 'react';
import {connect} from 'react-redux';

import {sodexoType} from './../../actions/paymentActions';

class Sodexo extends React.Component {
    render() {
        const {sodexo} = this.props;
        return (
            <div className="row">
            <hr />
                <div className="col-md-6">
                    <a 
                        className={sodexo.savedcard ? "btn_full" : "btn_full selected"} 
                        href="javascript:void(0)"
                        style={{backgroundColor:'#ff9800'}}
                        onClick={() => this.props.sodexoType(false)}
                    >New Card
                    </a>
                </div>
                <div className="col-md-6">
                    <a 
                        className={sodexo.savedcard ? "btn_full selected" : "btn_full"} 
                        href="javascript:void(0)"
                        style={{backgroundColor:'#8bc34a'}}
                        onClick={() => this.props.sodexoType(true)}

                    >Use Saved Card
                    </a>
                </div>
                
            </div>
        );
    }
}

export default connect(state => state.paymentpage.paymentoption.mealvouchers, {sodexoType})(Sodexo);