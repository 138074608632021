import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {setCategory} from './../../actions/categoryActions';
import {setSelectedRestaurant} from './../../actions/restActions';
import {toggleFoodCourt,setFoodCourtId,toggleVegOnly} from './../../actions/filterActions';

class MenuCategories extends React.Component {

    handleSelectCategory = (e) => {
        const id = e.currentTarget.id;
        const name = e.currentTarget.name;
        const cat =  {id,name};
        this.props.setCategory(cat);
    }

    backToRestaurants = () => {
        this.props.history.push('/restaurants');
	}

    handleVegOnlyChange = (e) => {
        this.props.toggleVegOnly(e.target.checked);
    }

    render() {
        return (
            <div>
                
                <div className="col-md-3" id="menu-cat">
                    <p id="menu-cat-back-button"><a href="javascript:void(0)" className="btn_side" onClick={this.backToRestaurants}>Back to Restaurants</a></p>
                    
                    <div id="veg-only-filter">
                        <h5>Show Veg Only</h5>
                        <label className="switch">
                            <input 
                                type="checkbox" 
                                onChange={(e) => this.handleVegOnlyChange(e)}
                                {...this.props.filters.vegOnly ? {checked: true} : {}}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    
                    <nav className="box_style_1" id="myScrollspy">
                        <ul className="nav nav-pills nav-stacked" id="cat_nav">
                            {
                                this.props.menu.map((category,index) => {
                                    return (<li>
                                                <a 
                                                    href={`#m${index}`}
                                                    {...this.props.category.id === category.id ? {className:'active'} : ''}
                                                    onClick={this.handleSelectCategory}
                                                    id={category.id}
                                                    name={category.name}
                                                >{category.name}
                                                </a>
                                            </li>)
                                })
                            }
                        </ul>
                    </nav>
                </div>

                {/* mobile device */}
                
                <div className="col-md-3" id="mobile-menu-cat">                    
                    <div className="box_style_1">
                        <ul id="cat_nav" style={{'height':'250px','overflowX':'hidden','overflowY':'scroll'}}>
                        {
                            this.props.menu.map((category,index) => {
                                return (<li>
                                            <a 
                                                href={`#m${index}`}
                                                {...this.props.category.id === category.id ? {className:'active'} : ''}
                                                onClick={this.handleSelectCategory}
                                                id={category.id}
                                                name={category.name}
                                            >{category.name}
                                            </a>
                                        </li>)
                            })
                        }
                        </ul>
                        <div id="close-menu-cat"><i class="icon-cancel-circle-2"></i></div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setCategory,
    setSelectedRestaurant,
    toggleFoodCourt,
    setFoodCourtId,
    toggleVegOnly
}

export default withRouter(connect(state => state, mapDispatchToProps)(MenuCategories));