export const addItemToCart = (data,netPrice,taxItems) => ({
    type: 'ADD_ITEM_TO_CART',
    data,
    netPrice,
    taxItems
});

export const updateItemCart = (data,netPrice,taxItems) => ({
    type: 'UPDATE_ITEM_CART',
    data,
    netPrice,
    taxItems
});

export const addRemoveItemFromCart = (data,netPrice,taxItems) => ({
    type: 'REMOVE_ITEM_CART',
    data,
    netPrice,
    taxItems
});

export const emptyCart = (data) => ({
    type: 'EMPTY_CART',
    data
});

export const setRestFoodCourt = (data) => ({
    type: 'SET_REST_FOOD_COURT',
    data
});

export const setCustomer = (data) => ({
    type: 'SET_CUSTOMER',
    data
});

export const resetCustomer = () => ({
    type: 'RESET_CUSTOMER'
});

export const setPaymentTxn = (data) => ({
    type: 'SET_PAYMENT_TXN',
    data
});

export const setOrderData = (data) => ({
    type: 'SET_ORDER_DATA',
    data
});

export const setOrderId = (data) => ({
    type: 'SET_ORDER_ID',
    data
});

export const reOrder  = (data) => ({
    type: 'REORDER',
    data
});