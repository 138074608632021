const orderReducer = (state = [], {type,data = "",netPrice = '',taxItems=[]}) => {
    let items;
    switch(type){
        case 'ADD_ITEM_TO_CART':
            return {
                ...state, 
                items:[...state.items,data], 
                itemscount: netPrice.itemscount,
                totalprice: netPrice.totalprice,
                grossamount: netPrice.grossamount,
                taxamount: netPrice.taxamount,
                taxitems: taxItems
            };
        
        case 'UPDATE_ITEM_CART':
            let updatedItems = state.items.filter(item => item.id !== data.id); 
            return {
                ...state, 
                items:[...updatedItems,data], 
                itemscount: netPrice.itemscount,
                totalprice: netPrice.totalprice,
                grossamount: netPrice.grossamount,
                taxamount: netPrice.taxamount,
                taxitems: taxItems
            };

        case 'REMOVE_ITEM_CART':

            return {
                ...state,
                items: data,
                itemscount: netPrice.itemscount,
                totalprice: netPrice.totalprice,
                grossamount: netPrice.grossamount,
                taxamount: netPrice.taxamount,
                taxitems: taxItems
            }

        case 'EMPTY_CART':
            return {
                    ...state,
                    items:[],
                    itemscount: 0,
                    grossamount: 0,
                    taxamount: 0,
                    totalprice: 0,
                    taxitems: [] 
                };

        case 'SET_REST_FOOD_COURT':
            return {
                ...state,
                foodcourt: data.foodcourt,
                foodcourtname: data.foodcourtname,
                parentId: data.parentId,
                restaurantname: data.restaurantname,
                imageUrl: data.imageUrl
            }

        case 'SET_CUSTOMER':
            return {
                ...state,
                restaurantcustomer: data.restaurantcustomer,
                customername: data.customername,
                customerno: data.customerno,
                createdby: data.restaurantcustomer
            }

        case 'RESET_CUSTOMER':
            return {
                ...state,
                restaurantcustomer: "",
                customername: "",
                customerno: ""
            }
        case 'SET_PAYMENT_TXN':
            return {
                ...state,
                paymenttransactions: [data]
            }
        case 'SET_ORDER_DATA':
            return {
                ...state,
                placedtime: data.placedTime,
                paid: true,
                paidamount: data.paidamount
            }
        case 'SET_ORDER_ID':
            return {
                ...state,
                orderResponse: data
            }
        case 'REORDER':
            return data;
        default:
            return state;
    }
}

export default orderReducer;