const modalReducer = (state = [], {type,modalType,data}) => {
    switch(type){
        case 'OPEN_MODAL':
            return {
                isModalOpen: true,
                modalType,
                data
            };
        case 'CLOSE_MODAL':
            return {
                isModalOpen: false,
                modalType,
                data: {...state.data}
            }
        default:
            return state;
    }
};

export default modalReducer;