import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import FilterOptions from './FilterOptions';

import {openModal} from './../../actions/modalActions';

class Filters extends React.Component {

    render() {
		return (
			<div className="col-md-3">
				<div id="filters_col">
					<a 
						data-toggle="collapse" 
						href="#collapseFilters" 
						aria-expanded="true" 
						aria-controls="collapseFilters" 
						id="filters_col_bt
					"> 
					Filters 
						<i className="icon-plus-1 pull-right"></i>
					</a>

					<div className="collapse" id="collapseFilters">
						<FilterOptions />
					</div>
				</div>

				{/* mobile device */}
				<div id="mobile_filters_col">
					<a 
						href="javascript:void(0)"
					>
						<div aria-hidden="true" data-icon="&#x67;"></div>
					</a>	
				</div>
				<div id="disp-mobile-filters" className="row">
					<div className="col-sm-12">
						<h4>Filters</h4>
					</div>
					<div className="col-sm-12">
						<FilterOptions />
					</div>
					<div className="col-sm-12">
						<a href="javascript:void(0)" className="btn_side" id="filters-done-button">Done</a>
					</div>
					
				</div>
				<div id="filters-overlay"></div>
			</div>
		);
	}
}

const mapStateToProps = {
	openModal
};

export default withRouter(connect(state => state, mapStateToProps)(Filters));