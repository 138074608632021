import React from 'react';
import {connect} from 'react-redux';

import Order from './Order';

class OrderHistory extends React.Component {

    render() {
        return(
            <div>
            {
                this.props.orderHistory.map(order => <Order singleOrder={order}/>)
            }
            </div>
        );
    }
}

export default connect(state=>state)(OrderHistory);