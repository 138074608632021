import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {loadJsLibraries} from './../utils/LoadLibrary';
import {openModal} from './../actions/modalActions';

import loader from './../images/web-images/loading.gif';

class OrderFail extends React.Component {
    
    componentWillMount() {
        const {action} = this.props;

        loadJsLibraries();
        action && action === 'login' ? this.props.openModal('UserLogin',{buttonClick: false}) : '';
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.history.push('/payment-options');
        },4000);
    }
    
    render() {
        return (
            <div>              
                <div class="container margin_60_35">
                    <div class="row">
                        <div class="col-md-offset-3 col-md-6">
                            <div class="box_style_2">
                                <h2 class="inner">Oops! Your payment was failed</h2>
                                <div id="confirm">
                                    <i class="icon-frown" style={{color:'#f14300'}}></i>
                                    <div style={{textAlign: 'center'}}>
                                        <img src={loader} width="120px"/>
                                        <h4>Try again with another payment option</h4>
                                        <h5>Please wait while we are redirecting you to the payment page...</h5>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
}
const mapDispatchToProps = {
    openModal
};

export default withRouter(connect(state => state,mapDispatchToProps)(OrderFail));