import axios from 'axios';

const baseURL = 'https://api.ampcome.com/api';

export const generateTxn = (body,accesstoken) => {
    const api = '/gokhana/paymenttransaction/record';
    return axios.post(`${baseURL}${api}`,body,{
        headers: {"Authorization": accesstoken,"Content-Type":"application/json"}
    }).then(res => res);
}

export const getSavedCard = (id,accesstoken) => {
    const api = `/gokhana/restaurantcustomer/${id}/savedcards?cardtypes=["sodexo"]`;

    return axios.get(`${baseURL}${api}`,{
        headers: {'Authorization': accesstoken}
    }).then(res => res);
}

export const getResponse = (id,accesstoken) => {
    const api = `/gokhana/paymenttransaction/${id}/update`;

    return axios.post(`${baseURL}${api}`,null,{
        headers: {'Authorization': accesstoken}
    }).then(res => res);
}

export const createFoodCourt = (restId,body,accesstoken) => {
    const api = `/gokhana/restaurant/${restId}/foodorder`;

    return axios.post(`${baseURL}${api}`,body,{
        headers: {'Authorization': accesstoken}
    }).then(res => res);
}