const customerReducer = (state = [],{type,data}) => {
    switch(type) {
        case 'AUTHENTICATE_USER':
            return {...state, isAuthenticated:data};
        case 'SELECT_TAB':
            return {
                ...state, 
                selecttab : {
                    orders: data === 'orders' ? true : false,
                    profile: data === 'profile' ? true: false
                }
            }
        case 'SET_SELECTED_ORDER':
            return {
                ...state,
                selectedOrder : data
            }
        case 'GET_SET_CUSTOMER_DETAILS':
            return {
                ...state,
                updateCustomerDetails: data
            }
        case 'SET_EMAIL':
            return {
                ...state,
                updateCustomerDetails: {
                    ...state.updateCustomerDetails,
                    email: data
                }
            }
        case 'SET_NAME':
            return {
                ...state,
                updateCustomerDetails: {
                    ...state.updateCustomerDetails,
                    name: data
                }
            }
        case 'TOGGLE_ISUPDATED':
            return {
                ...state,
                isUpdated: data
            }
        default: 
            return state;
    }
}

export default customerReducer;