import React from 'react';
import paytmImg from './../../images/web-images/paytm.png';
import { connect } from 'react-redux';
import {paytmWallet} from './../../actions/paymentActions';

class PaytmWallet extends React.Component {
    render() {
        const {wallet} = this.props;
        return (
            <div class="row">
                <div className="col-md-1 col-sm-1"></div>
                <div class="col-md-6 col-sm-6">
                    <div class="form-group">
                        <input 
                            type="radio"
                            name="wallet"
                            {...wallet.paytm.isActive ? {checked: true} : {}} 
                            onChange={() => this.props.paytmWallet('paytm')}
                        />
                        <img src={paytmImg} height="30px" style={{marginLeft:'15px'}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => state.paymentpage.paymentoption,{paytmWallet})(PaytmWallet);