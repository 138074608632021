export const authenticateUser = (data) => ({
    type: 'AUTHENTICATE_USER',
    data
});

export const selectTab = (data) => ({
    type: 'SELECT_TAB',
    data
});

export const setSelectedOrder = (data) => ({
    type: 'SET_SELECTED_ORDER',
    data
});

export const getSetCustDetails = (data) => ({
    type: 'GET_SET_CUSTOMER_DETAILS',
    data
});

export const setEmail = (data) => ({
    type: 'SET_EMAIL',
    data
});

export const setName = (data) => ({
    type: 'SET_NAME',
    data
});

export const toggleIsUpdated = (data) => ({
    type: 'TOGGLE_ISUPDATED',
    data
});