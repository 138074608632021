import React from 'react';
import {connect} from 'react-redux';

import GridListFoodCourts from './GridListFoodCourts';

class DisplayFoodCourts extends React.Component {
    render() {
        return (
            <div className="col-md-9">
            {
                this.props.restaurants.length !==0 ? 
                (this.props.restaurants.map((foodcourt) => {
                    return foodcourt.entityName === 'foodcourt' ? <GridListFoodCourts outlet={foodcourt} /> : '';
                }))
                : <div className="error-msg"><h5>Oops! No Food Courts Found</h5></div>
            }
            </div>
        );
    }
}

export default connect(state => state)(DisplayFoodCourts);