import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import SearchLocationBar from './SearchLocationBar';

import {setLocation} from './../../actions/locationActions';
import {toggleLoader} from './../../actions/loaderActions';

class searchBarPopup extends React.Component {

    changeLocation = (e) => {
        const loc = e.currentTarget;
        let location = {};
        location.latitude = loc.attributes.getNamedItem('data-lat').value;
        location.longitude = loc.attributes.getNamedItem('data-lng').value;
        location.city = loc.attributes.getNamedItem('data-city').value;
        location.state = '';

        this.props.setLocation(location);
        this.props.toggleLoader(true);
        setTimeout(() => {
            this.props.history.push('/restaurants');
        },1000); 
    }

    render() {
        return (
            <div className="row" id="search-location-popup">
                <div className="col-md-12">
                    <div id="subheader" style={{"backgroundColor":"#ffffff","color":"#000000","padding-top":"20px"}} >
                        <div id="sub_content" style={{"backgroundColor":"#ffffff","color":"#000000"}} >
                            <div className="col-md-8 col-sm-12" style={{"textAlign":"left"}}>
                                <SearchLocationBar />    
                                <div style={{"marginTop":"20px","fontSize":"11px"}}>Locations</div>              
                                <a 
                                    href="javascript:void(0)" 
                                    style={{"color":"#000000"}} 
                                    onClick={this.changeLocation}
                                    data-lat="12.9715987"
                                    data-lng="77.59456269999998"
                                    data-city="Bangalore"
                                >Bangalore</a> &nbsp;
                                <a 
                                    href="javascript:void(0)" 
                                    style={{"color":"#000000"}} 
                                    onClick={this.changeLocation}
                                    data-lat="12.9260308"
                                    data-lng="77.6762463"
                                    data-city="Bellandur"
                                >Bellandur</a> &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = {
    setLocation,
    toggleLoader
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(searchBarPopup));