import React from 'react';
import { connect } from 'react-redux';

class RestaurantBanner extends React.Component {

    getCuisineName = (id) => {
        return this.props.cuisines.find(cuisine => {
            return cuisine.id === id;
        });
    }

    render() {
        return(
            <section style={{"minHeight":"100px","marginTop":"50px","backgroundColor":"#f9f9f9","borderBottom":"1px solid #ddd","borderTop":"1px solid #ddd"}}>
                    <div  className="container">
                    <table>
                        <tr>
                            <td style={{"width":"150px"}}>
                                <div className="thumb" >
                                    <img src={this.props.restaurant.imageUrl} alt="" style={{width: '120px'}}/>
                                </div>
                            </td>
                            <td>                  
                                <h4>{this.props.restaurant.name}</h4>
                                <div>
                                    {
                                        this.props.restaurant.id ? this.props.restaurant.cuisines.map((id) => {
                                            return <em>{this.getCuisineName(id) ? this.getCuisineName(id).name : ''} &nbsp;&nbsp;</em>
                                        }) : ''
                                    }
                                </div>                     
                                {this.props.restaurant.address}
                                <p style={{color: '#ff0000', fontWeight: 'bold'}}>{!this.props.restaurant.isOpen ? 'Closed Now' : ''}</p>
                            </td>
                        </tr>
                        </table>                   
                   
                    </div>
            </section>
        );
    }
}

export default connect(state => state)(RestaurantBanner);